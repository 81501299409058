/** @jsx jsx */
import React, { forwardRef, Fragment } from 'react'
import moment from 'moment'
import { Badge, Box, Flex, jsx } from 'theme-ui'
import InfoCard from '../shared/InfoCard'

import FeedbackButton from '../shared/FeedbackButton'
import IconCircle from '../shared/IconCircle'
import { GatsbyImage } from 'gatsby-plugin-image'

import SmartPlayer from '../shared/SmartPlayer'
import getReportUrgencyColor from '../../utils/getReportUrgencyColor'
import useFilterBulletinByPath from '../../hooks/useFilterBulletinByPath'
import { useSearchParams } from '../../hooks/useSearchParams'

const Bulletin = forwardRef(
  (
    {
      beachName,
      hasAgencyConnected,
      bulletins,
      agencyLogo,
      agencyName,
      hideShameCard,
    },
    ref
  ) => {
    const { device } = useSearchParams()
    const bulletinsForThisPath = useFilterBulletinByPath(bulletins)

    const activeBulletins = bulletinsForThisPath?.filter((_, k) =>
      device === 'tv' ? k < 1 : true
    )

    return hideShameCard && !hasAgencyConnected ? (
      <Fragment />
    ) : hasAgencyConnected ? (
      !activeBulletins || activeBulletins?.length < 1 ? (
        <Fragment />
      ) : (
        <>
          <InfoCard
            sx={{ marginBottom: 0 }}
            description={activeBulletins?.map((bulletin, key) => (
              <Box
                key={key}
                sx={{
                  width: '100%',
                  borderBottom:
                    key < activeBulletins.length - 1 && '1px solid #ccc',
                  my: 4,
                }}
                ref={ref}
              >
                <Flex key={key} sx={{ flexDirection: 'column' }}>
                  <Flex sx={{ gap: '5px', alignItems: 'center' }}>
                    {agencyLogo && (
                      <IconCircle
                        styles={{
                          width: ['40px', '55px'],
                          height: ['40px', '55px'],
                          fontWeight: 700,
                          marginRight: ['8px', '14px'],
                          alignSelf: ['start', 'center'],
                          borderRadius: '50%',
                          display: 'flex', // to center the image vertically and horizontally
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'hidden', // ensure the image doesn't spill out
                        }}
                      >
                        <GatsbyImage
                          image={agencyLogo.gatsbyImageData}
                          alt={`${agencyName} Logo`}
                          sx={{
                            width: '100%', // fills the container
                            height: '100%', // fills the container
                            objectFit: 'cover', // ensures the image covers the container without spilling out
                          }}
                        />
                      </IconCircle>
                    )}

                    <Box sx={{ flex: 1 }}>
                      <h2
                        sx={{
                          variant: 'styles.cardHeader',
                          mb: 1,
                          width: '100%',
                          textAlign: 'left',
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        {bulletin?.title}{' '}
                        {bulletin?.urgency === 'HIGH' && (
                          <Box>
                            <Badge
                              sx={{
                                backgroundColor: getReportUrgencyColor(
                                  bulletin?.urgency
                                ),
                              }}
                            >
                              {bulletin?.urgency}
                            </Badge>
                          </Box>
                        )}
                      </h2>
                      <Box
                        as="time"
                        sx={{
                          textAlign: 'left',
                          display: 'block',
                        }}
                      >
                        {bulletin?.createdAt &&
                          !bulletin?.hideTimestamp &&
                          `${moment(bulletin?.createdAt).format(
                            'dddd, MMMM Do,'
                          )} at ${moment(bulletin?.createdAt).format(
                            'hh:mm A'
                          )}`}
                      </Box>
                    </Box>
                  </Flex>
                  <Box
                    sx={{
                      variant: 'styles.p',
                      mb: 4,
                      display: device === 'tv' ? '-webkit-box' : null,
                      WebkitLineClamp: device === 'tv' ? '5' : null,
                      WebkitBoxOrient: device === 'tv' ? 'vertical' : null,
                      overflow: 'hidden',
                      mt: '0px',
                      textAlign: 'left',
                    }}
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: bulletin?.message,
                    }}
                  />
                  {device !== 'tv' && bulletin?.videoUrl && (
                    <SmartPlayer
                      url={
                        bulletin?.videoUrl.includes('shorts')
                          ? `${bulletin?.videoUrl.replace(
                              'shorts/',
                              'watch?v='
                            )}`
                          : bulletin?.videoUrl
                      }
                      width="auto"
                      height="300px"
                    />
                  )}
                </Flex>
              </Box>
            ))}
          />
        </>
      )
    ) : (
      <Box sx={{ textAlign: 'left' }}>
        <h2>😞 This area's safety organization is not on SafeBeachDay</h2>
        <p>
          SafeBeachDay is a public portal for beach safety agencies and
          lifeguards. Our goal is to help first responders provide real-time and
          credible safety information to the public.
        </p>
        <FeedbackButton
          buttonText={'Ask them to join SafeBeachDay'}
          metadata={{
            Beach: beachName,
          }}
          displayAs={'button'}
        />
      </Box>
    )
  }
)

export default Bulletin
