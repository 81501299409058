/** @jsx jsx */
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Box, Button, Flex, jsx } from 'theme-ui'
import { ModalButton } from './shared/ModalButton'
import { PulsingIndicator } from './shared/PulsingIndicator'

const LiveFeedModal = ({ liveFeeds }) => {
  const [liveFeed, setLiveFeed] = useState(liveFeeds[0])

  return (
    <ModalButton
      modalHeader={'Live Cams'}
      modalHeight="auto"
      buttonText={'See Live Cams'}
      modalCloseButtonOpacity={true}
      buttonIcon={<PulsingIndicator color={'red'} />}
      renderModalContent={() => {
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Box sx={{ height: ['250px', null, '400px'] }}>
              <ReactPlayer
                style={{ marginTop: '20px' }}
                url={
                  liveFeed?.url.includes('shorts')
                    ? `${liveFeed?.url.replace('shorts/', 'watch?v=')}`
                    : liveFeed?.url
                }
                width="auto"
                height="100%"
              />
            </Box>
            <Box sx={{ p: 20 }}>
              <h4>{`${liveFeeds.length} Available Camera${
                liveFeeds.length > 1 ? 's' : ''
              }`}</h4>
              {liveFeeds?.map((lf, key) => (
                <Flex
                  key={key}
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    my: 20,
                    border: 'default',
                    alignItems: 'center',
                  }}
                >
                  {lf.name}{' '}
                  <Button
                    onClick={() => setLiveFeed(lf)}
                    sx={{ borderRadius: '30px' }}
                    variant={lf === liveFeed ? 'success' : 'primary'}
                  >
                    {lf === liveFeed ? 'Viewing' : 'View'}
                  </Button>
                </Flex>
              ))}
            </Box>
          </Flex>
        )
      }}
    />
  )
}

export default LiveFeedModal
