/** @jsx jsx */
import { jsx } from 'theme-ui'

export default function FlagIcon(props) {
  return (
    <svg
      width="22" // 17
      height="30" // 22
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0769 9.61063C16.1896 9.72488 16.266 9.86997 16.2964 10.0276C16.3267 10.1852 16.3097 10.3483 16.2475 10.4963C16.1865 10.6446 16.083 10.7716 15.95 10.8613C15.817 10.9509 15.6604 10.9992 15.5 11H4.9375V16.6875C5.58396 16.6875 6.20395 16.9443 6.66107 17.4014C7.11819 17.8585 7.375 18.4785 7.375 19.125V20.75C7.375 20.9655 7.2894 21.1722 7.13702 21.3245C6.98465 21.4769 6.77799 21.5625 6.5625 21.5625H1.6875C1.47201 21.5625 1.26535 21.4769 1.11298 21.3245C0.960603 21.1722 0.875 20.9655 0.875 20.75V19.125C0.875 18.4785 1.13181 17.8585 1.58893 17.4014C2.04605 16.9443 2.66603 16.6875 3.3125 16.6875V1.25C3.3125 1.03451 3.3981 0.827849 3.55048 0.675476C3.70285 0.523102 3.90951 0.4375 4.125 0.4375C4.34049 0.4375 4.54715 0.523102 4.69952 0.675476C4.8519 0.827849 4.9375 1.03451 4.9375 1.25H15.5C15.6604 1.2508 15.817 1.29907 15.95 1.38871C16.083 1.47836 16.1865 1.60537 16.2475 1.75375C16.3097 1.90171 16.3267 2.06479 16.2964 2.22241C16.266 2.38003 16.1896 2.52512 16.0769 2.63937L12.5831 6.125L16.0769 9.61063Z"
        sx={{ fill: props.color }}
      />
    </svg>
  )
}
