import { css } from '@emotion/react'
import { useBreakpointIndex } from '@theme-ui/match-media'
import theme from '../gatsby-plugin-theme-ui'

export const useWindowSize = () => {
  const breakpoint = useBreakpointIndex({
    defaultIndex: 2,
  })

  return {
    mobile: breakpoint === 0,
    tablet: breakpoint === 1,
    desktop: breakpoint === 2,
    smallScreen: breakpoint < 2,
    tabletDown: breakpoint < 1,
  }
}

export const mobile = (body) => css`
  @media (max-width: ${theme.responsive.small}px) {
    ${body};
  }
`
export const tablet = (body) => css`
  @media (max-width: ${theme.responsive.large}px) {
    ${body};
  }
`

// import { css } from '@emotion/core'
// import { useState, useEffect } from 'react'
// import theme from 'gatsby-plugin-theme-ui'

// export const useWindowSize = () => {
//   const isClient = typeof window === 'object'

//   function getSize() {
//     return {
//       width: isClient ? window.innerWidth : undefined,
//       height: isClient ? window.innerHeight : undefined,
//     }
//   }

//   function getDeviceType(windowInfo) {
//     if (windowInfo.width <= theme.responsive.small) {
//       return 'mobile'
//       // } else if (
//       //   windowInfo.width > theme.responsive.small &&
//       //   windowInfo.width < theme.responsive.large
//       // ) {
//       //   return 'tablet'
//     } else if (windowInfo.width > theme.responsive.small) {
//       return 'desktop'
//     }
//   }
//   const [deviceType, setDeviceType] = useState(getDeviceType(getSize()))

//   useEffect(() => {
//     if (!isClient) {
//       return false
//     }
//     function handleResize() {
//       const resize = getDeviceType(getSize())
//       if (resize !== deviceType) {
//         setDeviceType(resize)
//       }
//     }
//     window.addEventListener('resize', handleResize)
//     return () => window.removeEventListener('resize', handleResize)
//   }) // Empty array ensures that effect is only run on mount and unmount

//   return {
//     deviceType,
//   }
// }

// export const mobile = body => css`
//   @media (max-width: ${theme.responsive.small}px) {
//     ${body};
//   }
// `
// export const tablet = body => css`
//   @media (max-width: ${theme.responsive.large}px) {
//     ${body};
//   }
// `
