/** @jsx jsx */
import React from 'react'
import { Badge, jsx } from 'theme-ui'
import moment from 'moment'
import Bullet from '../shared/Bullet'
import EventModalContent from './EventModalContent'
import { ModalButton } from '../shared/ModalButton'

const Event = (props) => {
  const { eventTitle, eventDate, eventEnd } = props.eventInfo
  const { lastIndex } = props

  const isActive = eventEnd
    ? moment().isBetween(eventDate, eventEnd)
    : moment(eventDate).isSameOrAfter(moment(), 'hour')

  const formattedDate = moment(eventDate).format('MMMM DD, YYYY [at] hh:mma')
  const formatedEndDate =
    eventEnd && moment(eventEnd).format('MMMM DD, YYYY [at] hh:mma')

  return (
    <>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: lastIndex ? 'none' : '1px solid #EAEDFF',
          padding: '10px 0',
        }}
      >
        <Bullet
          label={
            <>
              {eventTitle} {isActive && <Badge>Today</Badge>}
            </>
          }
          info={
            formatedEndDate
              ? `${formattedDate} ${formatedEndDate && ` - ${formatedEndDate}`}`
              : formattedDate
          }
        />
        <div>
          <ModalButton
            buttonText={'View More'}
            buttonVariant={'viewMore'}
            modalCloseButtonOpacity={false}
            modalOverlayStyles={{
              padding: '0',
              margin: '0',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '0px',
            }}
            modalHeader={true}
            modalStyles={{
              height: ['96vh', '90vh'],
            }}
            modalContentStyles={{
              height: '90hv',
              padding: ['10px', '40px 90px'],
            }}
            renderModalContent={({ isModalOpen, handleToggleModal }) => {
              return (
                <EventModalContent
                  isModalOpen={isModalOpen}
                  toggleModal={handleToggleModal}
                  dateInfo={
                    formatedEndDate
                      ? `${formattedDate} ${
                          formatedEndDate && ` - ${formatedEndDate}`
                        }`
                      : formattedDate
                  }
                  {...props}
                />
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Event
