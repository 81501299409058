/** @jsx jsx */
import React, { Fragment, useContext } from 'react'
import { jsx } from 'theme-ui'
import { useSearchParams } from '../hooks/useSearchParams'
import { TowerContext, WeatherContext } from '../utils'
import AgencyPageBlock from './main/AgencyPages/AgencyPageBlock'
import Bulletin from './main/Bulletin'
import EventsWidget from './main/EventsWidget'

import Overview from './main/Overview/Overview'
import { TitleCard } from './main/TitleCard'
import { TowerCard } from './main/TowerCard/TowerCard'
import InfoCard from './shared/InfoCard'

export const GenerateBlock = ({
  block,
  name,
  aquaticRiskLevels,
  qrCode,
  beachWebsiteLink,
  agencyLogo,
  agencyName,
  dailyReportRef,
  featureLists,
  ...rest
}) => {
  const { device } = useSearchParams()
  const { towerData } = useContext(TowerContext)
  const { weatherData, weatherDataLoading } = useContext(WeatherContext)
  const { __typename } = block

  if (__typename === 'ContentfulOverview') {
    return (
      <Overview
        data={block}
        beachName={name}
        weatherData={weatherData}
        aquaticRiskLevels={aquaticRiskLevels}
        globalOverride={towerData?.globalOverride}
        agencyLogo={agencyLogo}
        loading={weatherDataLoading}
        featureLists={featureLists}
        {...rest}
      />
    )
  }

  if (__typename === 'ContentfulTitleCard') {
    return <TitleCard {...block} />
  }

  if (__typename === 'ContentfulRulesSummary') {
    const pagesWithSlugs = block.rules?.filter((r) => r.slug || r.externalUrl)
    return pagesWithSlugs?.length > 0 ? (
      <AgencyPageBlock
        {...block}
        rules={pagesWithSlugs}
        qrCode={qrCode}
        beachWebsiteLink={beachWebsiteLink}
      />
    ) : (
      <Fragment />
    )
  }

  if (__typename === 'ContentfulBulletin') {
    return (
      <Bulletin
        hasAgencyConnected={rest.watchtowerAgencyId}
        ref={dailyReportRef}
        bulletins={towerData?.publicBulletins}
        agencyLogo={agencyLogo}
        agencyName={agencyName}
        hideShameCard={rest.hideShameCard}
      />
    )
  }

  if (__typename === 'ContentfulInfoCard') {
    return (
      <InfoCard
        title={block.title}
        description={
          <p sx={{ variant: 'styles.p' }}>{block.description?.description}</p>
        }
        bullets={block.bullets}
        bulletColumns={block.bulletColumns}
      />
    )
  }

  if (__typename === 'ContentfulOverallRiskCard') {
    return (
      <TowerCard
        contentfulData={block}
        towerData={towerData}
        weatherData={weatherData}
      />
    )
  }

  if (__typename === 'ContentfulEventWidget' && device !== 'tv') {
    return (
      <EventsWidget
        title={block.title}
        description={block.childContentfulEventWidgetDescriptionRichTextNode}
        events={block.events}
      />
    )
  }

  return null
}
