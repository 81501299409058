/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { Box, jsx } from 'theme-ui'
import InfoCard from '../../shared/InfoCard'
import Gauge from '../../shared/Gauge'
import {
  disclaimerOptions,
  descriptionOptions,
  descriptionTruncated,
  convertRichText,
} from '../../../utils/rich-text'
import TowerCardModalContent from './TowerCardModalContent'
import { ModalButton } from '../../shared/ModalButton'
import { useSearchParams } from '../../../hooks/useSearchParams'

export function TowerCard({ contentfulData, towerData, weatherData }) {
  const { device } = useSearchParams()
  const [waveHeight, setWaveHeight] = useState(null)
  useEffect(() => {
    if (weatherData) {
      setWaveHeight(weatherData?.waveHeight?.value)
    }
  }, [weatherData])

  const description = contentfulData.description

  let descriptionConvertedTruncated, descriptionConverted, disclaimerConverted

  if (contentfulData.description) {
    descriptionConvertedTruncated = convertRichText(
      description,
      descriptionTruncated
    )

    descriptionConverted = convertRichText(description, descriptionOptions)
  }

  const disclaimer = contentfulData.disclaimer

  if (disclaimer) {
    disclaimerConverted = convertRichText(disclaimer, disclaimerOptions)
  }

  return (
    <Box
      sx={{
        width: ['100%', '48%'],
        display: 'inline-block',
        flex: ['auto', '1 1 auto'],
      }}
    >
      <InfoCard title={contentfulData.title}>
        {contentfulData.dataDisplay === 'Gauge' && (
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '20px 0',
            }}
          >
            {towerData ? (
              <Gauge
                activityType={contentfulData.activityType[0]}
                hazardLevels={contentfulData.hazardLevels}
                towerData={towerData}
                waveHeight={waveHeight}
              />
            ) : (
              <div>Loading</div>
            )}
          </div>
        )}
        {device !== 'tv' && (
          <>
            {descriptionConvertedTruncated}
            <ModalButton
              buttonVariant={'viewMore'}
              buttonText={'View More'}
              modalCloseButtonOpacity={false}
              modalOverlayStyles={{
                padding: '0',
                margin: '0',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '0px',
              }}
              buttonStyles={{ fontSize: '16px' }}
              modalStyles={{
                height: ['96vh', '90vh'],
              }}
              modalContentStyles={{
                height: '90hv',
                padding: ['10px', '40px 90px'],
              }}
              renderModalContent={({ isModalOpen, handleToggleModal }) => {
                return (
                  <TowerCardModalContent
                    isModalOpen={isModalOpen}
                    toggleModal={handleToggleModal}
                    title={contentfulData.title}
                    hazardLevels={contentfulData.hazardLevels}
                    activityType={contentfulData.activityType[0]}
                    contenfulData={contentfulData}
                    description={descriptionConverted}
                    disclaimer={disclaimerConverted}
                    towerData={towerData}
                    waveHeight={waveHeight}
                    beachOverride={towerData?.globalOverride}
                  />
                )
              }}
            />
          </>
        )}
      </InfoCard>
    </Box>
  )
}
