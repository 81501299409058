/** @jsx jsx */
import React, { useEffect, useState, Fragment } from 'react'
import { jsx, Box, Button, Flex } from 'theme-ui'
import SMSAlerts from '../../icons/SMSAlerts'
import { ModalButton } from './ModalButton'
import { useLocalStorage } from 'react-use'
import { SUB_TO_BEACHES } from '../../operations/mutations'
import PhoneInput from './PhoneInput'
import moment from 'moment'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { uniq } from 'lodash'
import { useMutation } from '@apollo/client'

// Desktop/Large screen background img
const SubscribeButtonBgImage = () => {
  return (
    <StaticImage
      src="../../images/SignupBackground.png"
      alt="Desktop background image for alerts signup modal"
      width={250}
      css={{ width: '100%', height: '100%' }}
    />
  )
}

// Small screen/mobile background img
const SubscribeButtonMobileBgImage = () => {
  return (
    <StaticImage
      src="../../images/SignupBackgroundMobile.png"
      alt="Mobile background image for alerts signup modal"
      width={250}
      css={{ width: '100%', height: '100%' }}
    />
  )
}

export const SubscribeButton = ({
  beachName,
  watchtowerAgencyId,
  agencyLogo,
  buttonStyles,
  hideSubscribeButton,
}) => {
  const [subscribed, setSubscribed] = useLocalStorage('subscribed', false)
  const [declinedSub, setDeclinedSub] = useLocalStorage('declinedSub', false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const notDeclinedRecenlty =
      !declinedSub || moment(declinedSub).diff(moment(), 'days') > 1

    if (
      notDeclinedRecenlty &&
      !subscribed?.lists?.includes(watchtowerAgencyId)
    ) {
      const timer = setTimeout(() => {
        setOpen(true)
      }, 30000)

      return () => {
        setOpen(false)
        clearTimeout(timer)
      }
    }
  }, [subscribed, declinedSub])

  if (!watchtowerAgencyId || hideSubscribeButton) return <Fragment />

  return (
    <Box
      sx={{
        flex: ['1 1 50%', '0 0 auto'],
      }}
    >
      <ModalButton
        header={null}
        isModalOpen={open}
        modalHeight={'100%'}
        modalContentStyles={{ padding: '0px' }}
        buttonStyles={{ height: '100%', ...buttonStyles }}
        modalStyles={{ maxWidth: '800px', height: '500px' }}
        onClose={() => setDeclinedSub(new Date().toISOString())}
        modalCloseButtonOpacity={true}
        buttonText={'Subscribe to Alerts'}
        buttonIcon={<SMSAlerts width={20} height={20} />}
        renderModalContent={({ handleToggleModal }) => {
          return (
            <Flex
              sx={{
                height: '100%',
                borderRadius: '0.2rem',
                flexDirection: ['column', 'row'],
              }}
            >
              <Flex
                sx={{
                  flexBasis: '40%',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: ['6px', '0px'],
                  borderBottomLeftRadius: ['0px', '6px'],
                  maxHeight: ['25%', '100%'],
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 1,
                    width: ['80px', '50%'],
                    height: ['80px', '150px'],
                    left: ['auto', 0],
                    top: ['auto', '4%'],
                    borderTopRightRadius: ['100%', '75px'],
                    borderBottomRightRadius: ['100%', '75px'],
                    borderTopLeftRadius: ['100%', '0px'],
                    borderBottomLeftRadius: ['100%', '0px'],
                    backgroundColor: 'white',
                    opacity: '0.8',
                    overflow: 'hidden',
                    alignSelf: 'center',
                  }}
                >
                  <Flex
                    sx={{
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '10%',
                    }}
                  >
                    <GatsbyImage
                      image={agencyLogo?.gatsbyImageData}
                      style={{
                        width: ['10px', '125px'],
                        height: ['10px', '125px'],
                        padding: '0px',
                        borderRadius: '100%',
                        overflow: 'visible',
                      }}
                      layout={'fullWidth'}
                      objectPosition={'50% 50%'}
                      alt={`${beachName} Lifeguarding Agency Logo`}
                    />
                  </Flex>
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: ['block', 'none'],
                  }}
                >
                  <SubscribeButtonMobileBgImage />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: ['none', 'block'],
                  }}
                >
                  <SubscribeButtonBgImage />
                </Box>
              </Flex>
              <Flex
                sx={{
                  flexBasis: ['100%', '60%'],
                  flexDirection: 'column',
                  padding: [3, 4],
                }}
              >
                <Flex>
                  <h1
                    sx={{
                      fontSize: [5, 6],
                      marginTop: '0px',
                      marginBottom: 4,
                      textAlign: ['center', 'start'],
                      color: 'redOrange',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                      flexBasis: '100%',
                    }}
                  >
                    Subscribe to {beachName} Safety Alerts
                  </h1>
                </Flex>
                <p
                  sx={{
                    my: '0px',
                    mb: 4,
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  Stay connected to {beachName}. Sign up to get real-time beach
                  safety notifications from {beachName} lifeguards!
                </p>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <SubscribeForm
                    beachName={beachName}
                    beachListId={watchtowerAgencyId}
                    onFinish={(id, listId) => {
                      setSubscribed((prev) => ({
                        id,
                        lists: uniq([listId].concat(prev.lists || [])),
                      }))
                      handleToggleModal()
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          )
        }}
      />
    </Box>
  )
}

export const SubscribeForm = ({ beachListId, onFinish }) => {
  const [subscribeToBeaches, { loading, error }] = useMutation(SUB_TO_BEACHES)

  const [formValues, setFormValues] = useState({
    agencyIds: [beachListId],
  })

  async function handleSubmit(event) {
    event.preventDefault()

    const { agencyIds, ...rest } = formValues
    const { data } = await subscribeToBeaches({
      variables: {
        agencyIds,
        subscriber: {
          ...rest,
        },
      },
    })

    if (data) {
      onFinish(data?.subscribeToAgencies?.data?.subscriberId, beachListId)
    }
  }

  return (
    <form
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={handleSubmit}
    >
      <PhoneInput
        styling={{ marginBottom: '12px' }}
        value={formValues.phone}
        setValue={(val) => {
          setFormValues((prev) => ({
            ...prev,
            phone: val || '',
          }))
        }}
        required
      />
      <Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
        <Button
          sx={{
            borderRadius: '25px',
            paddingX: 4,
            width: ['100%'],
          }}
        >
          {loading ? 'Signing up...' : 'Sign up!'}
        </Button>
        {error && <p>{error.message}</p>}
      </Flex>
    </form>
  )
}
