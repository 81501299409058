/** @jsx jsx */
import React from 'react'
import { Flex, jsx } from 'theme-ui'
import WidgetContainer from '../shared/WidgetContainer'

export const TitleCard = ({ title }) => {
  return (
    <WidgetContainer>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Flex sx={{ flex: 1 }}>
          <h2 sx={{ variant: 'styles.cardHeader', display: 'inline' }}>
            {title}
          </h2>
        </Flex>
      </Flex>
    </WidgetContainer>
  )
}
