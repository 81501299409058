/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react'
import { jsx, Flex, Box, Badge } from 'theme-ui'

import useGenerateLocationRiskInformation from '../../../hooks/useGenerateLocationRiskInformation'

const TowerListItem = ({
  towerName,
  aquaticRiskLevels,
  weatherData,
  riskData,
  override,
  globalOverride,
  overrideReason,
  stingrays,
  jellyfish,
}) => {
  const { riskColor, riskStatus, isClosed, hideFromPublic, hasEvent } =
    useGenerateLocationRiskInformation({
      aquaticRiskLevels,
      weatherData,
      riskData,
      override,
      globalOverride,
    })

  if (hideFromPublic) {
    return <Fragment />
  }

  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        gap: 20,
        bg: 'white',
        pb: 10,
        pt: '5px',
        px: '15px',
        justifyContent: 'flex-start',
        flexDirection: ['column', null, 'row'],
      }}
    >
      <Box sx={{ flex: 1, width: ['100%', null, 'auto'] }}>
        <Box sx={{ fontWeight: 'bold', fontSize: 0, mb: '5px' }}>
          Tower & Risk
        </Box>
        <Flex sx={{ gap: '10px', alignItems: 'center' }}>
          {towerName}
          <Badge sx={{ bg: riskColor }}>{riskStatus}</Badge>
        </Flex>
      </Box>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box sx={{ fontWeight: 'bold', fontSize: 0, mb: '5px' }}>
          Recent Hazards
        </Box>
        <Flex
          sx={{
            gap: '10px',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}
        >
          {stingrays < 1 && jellyfish < 1 ? (
            '-'
          ) : (
            <>
              <Badge>{stingrays > 1 && 'Stingrays'}</Badge>
              <Badge> {jellyfish > 1 && 'Jellyfish'} </Badge>
            </>
          )}
        </Flex>
      </Box>
      <Box sx={{ flex: 1, width: ['100%', null, 'auto'] }}>
        <Box sx={{ fontWeight: 'bold', fontSize: 0, mb: '5px' }}>
          Tower Status
        </Box>
        <Box>
          {isClosed ? 'Closed' : '-'} {hasEvent ? ':Has Event' : ''}
        </Box>
      </Box>

      <Box sx={{ flex: 2 }}>
        <Box sx={{ fontWeight: 'bold', fontSize: 0, mb: '5px' }}>Details</Box>
        <Box>{overrideReason || '-'}</Box>
      </Box>
    </Flex>
  )
}

export default TowerListItem
