import React from 'react'

function BulletList(props) {
  const title = props.title || 'bullet list'

  return (
    <svg
      height="12"
      width="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill="#f7f7f7">
        <rect height="2" width="8" fill="#f7f7f7" rx="0.5" x="4" />
        <rect height="2" width="8" fill="#f7f7f7" rx="0.5" x="4" y="3" />
        <rect height="2" width="8" fill="#f7f7f7" rx="0.5" x="4" y="7" />
        <rect height="2" width="8" fill="#f7f7f7" rx="0.5" x="4" y="10" />
        <circle cx="1.5" cy="1.5" r="1.5" />
        <circle cx="1.5" cy="8.5" r="1.5" />
      </g>
    </svg>
  )
}

export default BulletList
