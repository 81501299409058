/** @jsx jsx */
import { Box, jsx } from 'theme-ui'

// ex. The "Add to Calendar button"

export default function OrangeActionButton({
  text,
  styles,
  buttonType,
  ...props
}) {
  return (
    <Box
      type={buttonType || 'button'}
      sx={{
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#E26744',
        color: '#FFFFFF',
        padding: '12px 40px',
        fontSize: '16px',
        ...styles,
      }}
      as="button"
      {...props}
    >
      {text}
    </Box>
  )
}
