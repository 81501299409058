/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import theme from '../../gatsby-plugin-theme-ui'

import { Link, navigate } from 'gatsby'

import ChevronRight from '../../icons/chevron-right'
import ChevronLeft from '../../icons/chevron-left'

export default function BeachNavigation({
  leftNeighboringBeaches = [],
  rightNeighboringBeaches = [],
  slug,
  beach,
}) {
  const beachLinks = [
    ...leftNeighboringBeaches.map((bl) => ({
      ...bl,
      left: true,
    })),
    { name: beach.name, slug: beach.slug },
    ...rightNeighboringBeaches.map((bl) => ({
      ...bl,
      right: true,
    })),
  ]

  const linkStyles = {
    margin: '0 10px',
    overflowX: 'auto',
    textDecoration: 'none',
    fontSize: '16px',
    color: theme.colors.navLink,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }

  const firstRightLink = beachLinks.find((b) => b.right)
  const firstLeftLink = beachLinks.find((b) => b.left)

  return (
    <>
      <Flex
        sx={{
          display: ['flex', null, 'none'],
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexBasis: '70%',
            margin: '0px 10px',
            flex: 1,
          }}
        >
          <ChevronLeft
            styles={{
              margin: '0px',
              cursor: !firstLeftLink ? 'not-allowed' : 'pointer',
              opacity: !firstLeftLink ? 0.5 : 1,
            }}
            onClick={() => {
              firstLeftLink && navigate(`/${firstLeftLink.slug}`)
            }}
          />

          <button
            sx={{
              fontSize: '14px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textDecoration: 'none',
              color: theme.colors.navLink,
              fontWeight: 'bold',
              margin: '0px',
              marginRight: '5px',
              border: 'none',
              backgroundColor: 'transparent',
              padding: '0px',
              zIndex: 200,
            }}
          >
            {beach.name}
          </button>

          <ChevronRight
            onClick={() => {
              firstRightLink && navigate(`/${firstRightLink.slug}`)
            }}
            styles={{
              opacity: !firstRightLink ? 0.5 : 1,
              margin: '0px',
              cursor: !firstRightLink ? 'not-allowed' : 'pointer',
            }}
          />
        </Flex>
      </Flex>

      <Flex
        sx={{
          display: ['none', null, 'flex'],
          flex: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Flex sx={{ flex: 1, textAlign: 'right', justifyContent: 'flex-end' }}>
          {beachLinks
            .filter((b) => b.left)
            .map((beach) => {
              if (!beach) {
                return (
                  <div sx={{ visibility: 'hidden', margin: '0 10px' }}>
                    Null Beach
                  </div>
                )
              }
              return (
                <Link
                  key={`LeftBeach${beach.id}`}
                  to={`/${beach.slug}`}
                  sx={{
                    ...linkStyles,
                    fontWeight: beach.slug === slug ? 'bold' : null,
                    textDecoration: beach.slug === slug ? 'underline' : 'none',
                  }}
                >
                  <div
                    sx={{
                      width: '100%',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {beach.name}{' '}
                  </div>
                </Link>
              )
            })}
        </Flex>
        <Box sx={{ textAlign: 'center' }}>
          {beachLinks
            .filter((b) => b.slug === slug)
            .map((beach) => {
              if (!beach) {
                return (
                  <div sx={{ visibility: 'hidden', margin: '0 10px' }}>
                    Null Beach
                  </div>
                )
              }
              return (
                <Link
                  key={`CenterBeach${beach.id}`}
                  to={`/${beach.slug}`}
                  sx={{
                    ...linkStyles,
                    fontWeight: beach.slug === slug ? 'bold' : null,
                    textDecoration: beach.slug === slug ? 'underline' : 'none',
                    whiteSpace: 'pre',
                  }}
                >
                  {beach.name}
                </Link>
              )
            })}
        </Box>
        <Flex sx={{ flex: 1, textAlign: 'left', justifyContent: 'flex-start' }}>
          {beachLinks
            .filter((b) => b.right)
            .map((beach) => {
              if (!beach) {
                return (
                  <div sx={{ visibility: 'hidden', margin: '0 10px' }}>
                    Null Beach
                  </div>
                )
              }
              return (
                <Link
                  key={`RightBeach${beach.id}`}
                  to={`/${beach.slug}`}
                  sx={{
                    ...linkStyles,
                    fontWeight: beach.slug === slug ? 'bold' : null,
                    textDecoration: beach.slug === slug ? 'underline' : 'none',
                    whiteSpace: 'pre',
                  }}
                >
                  {beach.name}
                </Link>
              )
            })}
        </Flex>
      </Flex>
    </>
  )
}
