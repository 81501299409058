import React from "react"

function InfoIcon(props) {
  const title = props.title || "info icon"
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <path
        d="M7.52 0A7.53 7.53 0 000 7.52c.413 9.975 14.627 9.972 15.04 0A7.53 7.53 0 007.52 0zm0 13.99a6.478 6.478 0 01-6.47-6.47c.349-8.583 12.59-8.583 12.94 0a6.478 6.478 0 01-6.47 6.47z"
        fill={props.color || "#fff"}
      />
      <path
        d="M7.52 6.32a.525.525 0 00-.525.525v3.291a.525.525 0 101.05 0V6.845a.525.525 0 00-.525-.525zM7.15 4.344a.507.507 0 00-.144.27.525.525 0 10.143-.27z"
        fill={props.color || "#fff"}
      />
    </svg>
  )
}

export default InfoIcon