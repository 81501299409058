/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import TowerIcon from '../../../icons/tower-icon'
import Stingray from '../../../icons/stingray.svg'
import Jellyfish from '../../../icons/Jellyfish.png'

import styled from '@emotion/styled'

import { PulsingIndicator } from '../../shared/PulsingIndicator'
import ReactTooltip from 'react-tooltip'
import useGenerateLocationRiskInformation from '../../../hooks/useGenerateLocationRiskInformation'
import theme from '../../../gatsby-plugin-theme-ui'

const TowerWrapper = styled.div`
  .tower:hover {
    cursor: pointer;
  }
  .tooltip-class {
    padding: 20px;
  }
`

export default function Tower({
  towerId,
  offsetTop,
  offsetLeft,
  ratio = 1,
  imageRef,
  indicatorOnly,
  name,
  stingrays,
  jellyfish,
  rescues,
  description,
  aquaticRiskLevels,
  weatherData,
  override,
  overrideReason,
  globalOverride,
  platformTowerName,
  lat,
  lng,
  riskData,
  customOpenIcon,
  customCloseIcon,
}) {
  const {
    riskColor,
    riskStatus,
    isClosed,
    hasEvent,
    customIcon,
    textTopOffset,
    hideFromPublic,
  } = useGenerateLocationRiskInformation({
    aquaticRiskLevels,
    weatherData,
    riskData,
    override,
    globalOverride,
  })

  const openCloseIcon = isClosed
    ? customCloseIcon?.file?.url
    : customOpenIcon?.file?.url

  if (!riskStatus || hideFromPublic) {
    return <Fragment />
  }

  return (
    <TowerWrapper key={towerId} data-tip data-for={`${towerId}`}>
      <TowerToolTip
        towerId={towerId}
        offsetLeft={offsetLeft}
        stingrays={stingrays}
        towerName={platformTowerName || name}
        jellyfish={jellyfish}
        rescues={rescues}
        description={description || name}
        override={override}
        overrideReason={overrideReason}
        globalOverride={globalOverride}
        lat={lat}
        lng={lng}
        riskStatus={riskStatus}
        isClosed={isClosed}
        hasEvent={hasEvent}
      />
      <Flex
        sx={{
          alignItems: 'center',
          position: 'absolute',
          flexDirection: 'column',
          top: `${offsetTop * ratio * 100}%`,
          zIndex: 0,
          left: `${
            imageRef?.current && imageRef.current.clientWidth * offsetLeft
          }px`,
        }}
      >
        {(overrideReason || (indicatorOnly && override)) && (
          <Box
            sx={{
              position: 'absolute',
              top: '1%',
              right: 0,
              zIndex: 1000,
            }}
          >
            <PulsingIndicator
              id={towerId}
              key={towerId}
              noPulse={!indicatorOnly}
              color={!indicatorOnly ? theme.colors.highRisk : riskColor}
              large={indicatorOnly}
            />
          </Box>
        )}

        {!indicatorOnly || openCloseIcon ? (
          <>
            <Flex
              className="tower"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <>
                {openCloseIcon || customIcon ? (
                  <img
                    width={'auto'}
                    src={openCloseIcon || customIcon}
                    alt={name}
                  />
                ) : (
                  <TowerIcon color={riskColor} dropshadow />
                )}
                <div
                  sx={{
                    position: 'absolute',
                    top: textTopOffset ? `${textTopOffset}%` : '20%',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    fontSize: name?.length > 3 ? '13px' : '16px',
                  }}
                >
                  {name}
                </div>
              </>

              {isClosed && !openCloseIcon && (
                <Flex
                  sx={{
                    position: 'absolute',
                    height: '60px',
                    width: '160%',
                    minWidth: '40px',
                    bg: 'gray200',
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    bottom: -20,
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    textAlign: 'center',
                    zIndex: -1,
                    color: 'text',
                  }}
                >
                  Closed
                </Flex>
              )}

              {hasEvent && !openCloseIcon && (
                <Flex
                  sx={{
                    position: 'absolute',
                    height: '60px',
                    width: '160%',
                    minWidth: '40px',
                    bg: 'specialEvent',
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    bottom: -20,
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    textAlign: 'center',
                    zIndex: -1,
                    color: 'white',
                  }}
                >
                  Event
                </Flex>
              )}
            </Flex>

            {(stingrays > 1 || jellyfish > 1) && (
              <Flex
                sx={{
                  position: 'absolute',
                  height: 'auto',
                  width: '130%',
                  bg: 'purpleLight',
                  borderRadius: '3px',
                  fontWeight: 'bold',
                  p: '3px',
                  top: ['120%', null, null, '130%'],
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                  mt: hasEvent && '7px',
                  fontSize: '9px',
                  color: 'white',
                  zIndex: -1,
                }}
              >
                <Flex sx={{ alignItems: 'center', gap: '2px' }}>
                  {stingrays > 1 && (
                    <Box>
                      <img src={Stingray} height="26px" />
                    </Box>
                  )}
                  {jellyfish > 1 && (
                    <Box>
                      <img src={Jellyfish} height="25px" />
                    </Box>
                  )}
                </Flex>
                <Box>
                  {jellyfish > 1 && stingrays > 1
                    ? 'Jellyfish and Stingray'
                    : jellyfish > 1
                    ? 'Jellyfish'
                    : 'Stingray'}
                </Box>
              </Flex>
            )}
          </>
        ) : (
          <div></div>
        )}
      </Flex>
    </TowerWrapper>
  )
}

const TowerToolTip = ({
  towerId,
  towerName,
  riskStatus,
  stingrays,
  jellyfish,
  rescues,
  description,
  override,
  overrideReason,
  globalOverride,
  isClosed,
  hasEvent,
  lat,
  lng,
}) => {
  return (
    <ReactTooltip
      id={`${towerId}`}
      delayHide={80}
      place="bottom"
      sx={{
        padding: '20px',
      }}
      className="tooltip-class"
      aria-haspopup="true"
      clickable
    >
      <h3
        sx={{
          marginY: '0px',
          fontWeight: 'bold',
        }}
      >
        {towerName}
      </h3>
      {description}
      <div>
        <h4 sx={{ mb: '0px' }}>Risk level is {riskStatus} </h4>
        <p sx={{ mt: '2px' }}>
          (
          {override || globalOverride
            ? ' Indicated by Lifeguards'
            : 'Based on Wave Conditions'}
          )
        </p>
      </div>
      {isClosed && <h4>Currently closed</h4>}
      {hasEvent && <h4>Active event</h4>}
      {overrideReason && (
        <Box sx={{ maxWidth: '300px' }}>
          <h3 sx={{ mb: '0px', textDecoration: 'underline' }}>More details</h3>
          <p>{overrideReason}</p>
        </Box>
      )}
      {rescues ? <p>{rescues + ' recent rescues'} </p> : <Fragment />}

      {stingrays ? <p>{stingrays + ' recent stingrays'} </p> : <Fragment />}
      {jellyfish ? <p>{jellyfish + ' recent jellyfish'} </p> : <Fragment />}

      {lat && lng && (
        <a
          sx={{ color: 'white' }}
          target="__blank"
          rel="noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
        >
          Directions
        </a>
      )}
    </ReactTooltip>
  )
}
