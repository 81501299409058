/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import InfoCard from '../shared/InfoCard'
import Event from '../eventsWidget/Event'

export default function EventsWidget({ title, description, events }) {
  const nonExpiredEvents =
    events &&
    events.filter((ev) => {
      return new Date(ev.eventEnd || ev.eventDate) >= new Date()
    })

  return (
    <InfoCard title={title}>
      {nonExpiredEvents && nonExpiredEvents.length > 0 ? (
        nonExpiredEvents.map((event, index) => {
          return (
            <Event
              key={event.id}
              eventInfo={event}
              overallTitle={title}
              overallDescription={description}
              lastIndex={index === events.length - 1}
            />
          )
        })
      ) : (
        <div sx={{ padding: '10px 0' }}>
          There are currently no upcoming events
        </div>
      )}
    </InfoCard>
  )
}
