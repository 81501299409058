import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'

const SmartPlayer = ({ url, ...props }) => {
  if (!url) {
    return <Fragment />
  }

  const isImage = /\.(jpeg|jpg|gif|png|webp)$/i.test(url)

  if (isImage) {
    return (
      <img
        src={url}
        alt="Content"
        style={{
          width: 'auto',
          height: '300px',
          maxWidth: '100%',
          objectFit: 'contain',
          ...props.style,
        }}
      />
    )
  }

  const updatedUrl = url.includes('shorts')
    ? `${url.replace('shorts/', 'watch?v=')}`
    : url

  return <ReactPlayer url={updatedUrl} width="auto" height="300px" {...props} />
}

export default SmartPlayer
