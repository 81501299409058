/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react'
import { jsx } from 'theme-ui'

import theme from '../../../gatsby-plugin-theme-ui'
import ChevronLeft from '../../../icons/chevron-left.js'
import ChevronRight from '../../../icons/chevron-right.js'

export default function OverviewBullet({
  title,
  value,
  description,
  icon,
  transform,
  arrows,
  minHeight,
  onLeftClick,
  onRightClick,
  carouselBullet,
  customIcon,
}) {
  return (
    <div
      sx={{
        minWidth: '100%',
        padding: ['10px 1px', '10px'],
        border: '1px solid #EAEDFF',
        borderRadius: ['10px', '8px'],
        textAlign: 'center',
        transform: transform || null,
        minHeight: minHeight || '100px',
        transition: 'transform 0.5s',
      }}
    >
      <div>
        <div
          sx={{
            minHeight: [null, null, `${22.5 * 2}px`],
            display: [null, null, null, 'flex'],
            justifyContent: ['center', null, null, 'center'],
            alignItems: 'center',
            margin: ['5px', '10px 0'],
          }}
        >
          <h5
            sx={{
              variant: 'styles.h5',
            }}
          >
            {title}
          </h5>
        </div>
        {value && (
          <>
            <div
              sx={{
                position: 'relative',
                width: ['75%', '50%', '100%'],
                display: 'flex',
                justifyContent: carouselBullet ? 'space-between' : 'center',
                alignItems: 'center',
                margin: 'auto',
              }}
            >
              {arrows && (
                <button
                  onClick={onLeftClick}
                  sx={{
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 'none',
                    height: ['15px', '30px'],
                  }}
                >
                  <ChevronLeft width={'30'} height="100%" />
                </button>
              )}
              {customIcon}
              {icon && (
                <img
                  src={icon}
                  sx={{
                    width: ['20px', '30px'],
                    height: ['20px', '30px'],
                  }}
                />
              )}
              <h2
                sx={{
                  margin: ['10px 0', '5px'],
                }}
              >
                {value}
              </h2>
              {arrows && (
                <button
                  onClick={onRightClick}
                  sx={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    height: ['15px', '30px'],
                  }}
                >
                  <ChevronRight height={'100%'} />
                </button>
              )}
            </div>
          </>
        )}
        <p
          sx={{
            fontSize: [theme.styles.p.fontSize[0], '14px'],
            margin: ['0', '10px'],
          }}
        >
          {description}
        </p>
      </div>
    </div>
  )
}
