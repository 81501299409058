import { useLocation } from 'react-use'

const useFilterBulletinByPath = (bulletins = []) => {
  const { pathname } = useLocation()

  const activeBulletins = bulletins?.filter((i) =>
    i?.showOnPaths?.length > 0
      ? i?.showOnPaths?.includes(pathname.split('/').join(''))
      : true
  )

  return activeBulletins
}

export default useFilterBulletinByPath
