/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'
import { jsx, Flex, Box, Button, Checkbox, Label } from 'theme-ui'
import WidgetContainer from '../../shared/WidgetContainer'
import ParkingLabel from './ParkingLabel'
import HazardMap from './HazardMap'
import Tower from './Tower'
import Pier from './Pier'
import ScrollContainer from 'react-indiana-drag-scroll'
import styled from '@emotion/styled'
import { SubscribeButton } from '../../shared/SubscribeButton'
import DataLoading from '../../DataLoading'
import TowerListItem from './TowerListItem'
import ListIcon from '../../../icons/List'
import BeachMap from '../../../icons/BeachMap'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useWindowSize } from '../../../hooks/useWindowSize'

const MapContainer = styled.div`
  > .scroll-container:hover {
    cursor: grab;
  }
`

const ScrollIcon = (props) => {
  const title = props.title || 'scroll horizontal'
  return (
    <svg
      height="64"
      width="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g
        fill={props.color || '#FFFFFF'}
        stroke={props.color || '#FFFFFF'}
        strokeLinecap="square"
        strokeWidth="2"
        transform="translate(0.5 0.5)"
      >
        <line
          fill="none"
          strokeLinecap="butt"
          x1="39"
          x2="61"
          y1="15"
          y2="15"
        />
        <polyline fill="none" points=" 53,7 61,15 53,23 " />
        <line fill="none" strokeLinecap="butt" x1="20" x2="3" y1="15" y2="15" />
        <polyline fill="none" points=" 11,7 3,15 11,23 " />
        <line
          fill="none"
          stroke={props.color || '#FFFFFF'}
          x1="26"
          x2="26"
          y1="33"
          y2="39"
        />
        <path
          d="M33,31v-2.5 c0-1.933,1.567-3.5,3.5-3.5h0c1.933,0,3.5,1.567,3.5,3.5V31"
          fill="none"
          stroke={props.color || '#FFFFFF'}
        />
        <path
          d="M40,32v-1.5 c0-1.933,1.567-3.5,3.5-3.5h0c1.933,0,3.5,1.567,3.5,3.5V33"
          fill="none"
          stroke={props.color || '#FFFFFF'}
        />
        <path
          d="M47,32.5 c0-1.933,1.567-3.5,3.5-3.5h0c1.847,0,3.266,1.636,3.005,3.465L50,57H26c0-3.833-7-9.333-7-14v-7c0-1.657,1.343-3,3-3l4,0V10.5 C26,8.567,27.567,7,29.5,7h0c1.933,0,3.5,1.567,3.5,3.5V30"
          fill="none"
          stroke={props.color || '#FFFFFF'}
        />
      </g>
    </svg>
  )
}

const HoverIndicator = (props) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    setTimeout(() => setShow(false), 2500)
  }, [])

  return (
    <Box
      sx={{
        height: '100%',
        display: show ? ['flex', null, 'none'] : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        position: show ? 'absolute' : 'unset',
        transition: 'opacity 1s ease',
        top: 0,
        right: 0,
        zIndex: 100,
        bg: 'black',
        opacity: show ? 0.7 : 0.0,
        left: 0,
        bottom: 20,
        ...props.sx,
      }}
    >
      <ScrollIcon />
    </Box>
  )
}

const Map = ({
  piers,
  towers,
  weatherData,
  parking,
  mapBackgroundImage,
  beachName,
  hideSubscribeButton,
  towerHazardRatings,
  watchtowerAgencyId,
  agencyLogo,
  loading: dataLoading,
  layers,
}) => {
  const { smallScreen } = useWindowSize()
  const imageRef = useRef(null)
  const mobileRef = useRef(null)
  const [activeLayers, setActiveLayers] = useState([])

  const imageReady = imageRef?.current && mobileRef.current
  const loading = !imageReady || !towers || !weatherData || dataLoading

  function selectLayer(layer) {
    if (activeLayers.includes(layer)) {
      setActiveLayers(activeLayers.filter((l) => l !== layer))
    } else {
      setActiveLayers([...activeLayers, layer])
    }
  }

  return (
    <MapContainer>
      <ScrollContainer
        vertical={false}
        className="scroll-container"
        sx={{
          position: 'relative',
          height: ['500px', null, 'auto'],
        }}
      >
        {!loading && <HoverIndicator />}
        {loading && <DataLoading />}

        <BackgrounMapImage
          image={mapBackgroundImage}
          mobileRef={mobileRef}
          imageRef={imageRef}
        />
        {layers
          ?.filter((layer) => activeLayers?.includes(layer.title))
          ?.map((layer, k) => (
            <LayerImage layer={layer} key={k} />
          ))}
        {imageRef && imageRef.current && towers && (
          <>
            {towers.towers.map((tower, key) => (
              <Tower
                weatherData={weatherData}
                aquaticRiskLevels={towerHazardRatings}
                key={tower.towerId || key}
                imageRef={smallScreen ? mobileRef : imageRef}
                name={tower.towerName}
                lat={tower.latitude}
                lng={tower.longitude}
                globalOverride={towers.globalOverride}
                {...tower}
                riskData={{
                  ...tower,
                }}
                towerId={tower.towerId || key}
              />
            ))}

            {piers?.length &&
              piers.map((pier) => (
                <Pier
                  key={pier.pierName}
                  offsetLeft={pier.offsetLeft}
                  offsetTop={pier.offsetTop}
                  image={pier.pierImage.file.url}
                  alt={pier.pierName}
                  imageRef={imageRef}
                />
              ))}

            {parking?.length > 0 &&
              parking.map((parkingLot) => (
                <ParkingLabel
                  parkingInfoUrl={parkingLot.parkingInfoUrl}
                  key={parkingLot.id}
                  imageRef={imageRef}
                  offsetTop={parkingLot.offsetTop}
                  offsetLeft={parkingLot.offsetLeft}
                  width={parkingLot.width}
                  capacity={parkingLot.latestStatus}
                  name={parkingLot.parkingLotName || parkingLot.name}
                  id={parkingLot.id}
                />
              ))}
          </>
        )}
      </ScrollContainer>

      <Flex
        sx={{
          flexWrap: 'wrap',
          m: '4px 0px 16px',
          padding: [2, 0],
          gap: [2, 0],
        }}
      >
        <Flex
          sx={{
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
            display: 'flex',
            padding: '8px 16px',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <strong sx={{ width: ['100%', null, 'auto'], minWidth: '140px' }}>
            Toggle Layers:
          </strong>
          {layers?.map((layer, k) => (
            <Box sx={{ mr: '10px' }} key={k}>
              <Label
                aria-label={layer.title}
                sx={{ cursor: 'pointer', mb: '0px', p: '0px' }}
              >
                <Checkbox
                  name={layer.title}
                  onClick={() => {
                    selectLayer(layer.title)
                  }}
                  title={layer.title}
                />
                {layer.title}
              </Label>
            </Box>
          ))}
        </Flex>
        <HazardMap towerHazardRatings={towerHazardRatings} />

        <SubscribeButton
          beachName={beachName}
          watchtowerAgencyId={watchtowerAgencyId}
          agencyLogo={agencyLogo}
          buttonStyles={{
            display: ['inherit', 'none'],
            alignSelf: 'center',
          }}
          hideSubscribeButton={hideSubscribeButton}
        />
      </Flex>
    </MapContainer>
  )
}

const List = ({ towers, weatherData, towerHazardRatings }) => {
  return (
    <Flex
      sx={{
        height: '500px',
        p: '20px',
        flexDirection: 'column',
        overflowY: 'scroll',
        maxWidth: 'large',
        margin: 'auto',
        width: '100%',
        gap: 20,
      }}
    >
      {towers.towers.map((tower, key) => (
        <TowerListItem
          weatherData={weatherData}
          aquaticRiskLevels={towerHazardRatings}
          key={tower.towerId || key}
          name={tower.towerName}
          lat={tower.latitude}
          lng={tower.longitude}
          globalOverride={towers.globalOverride}
          {...tower}
          riskData={{
            ...tower,
          }}
          towerId={tower.towerId || key}
        />
      ))}
    </Flex>
  )
}

const BackgrounMapImage = ({ image, mobileRef, imageRef }) => {
  return (
    <>
      <img
        ref={mobileRef}
        sx={{
          display: ['block', null, 'none'],
          position: ['absolute', null, 'unset'],
          width: ['auto', null, '100%'],
          borderRadius: [0, '20px'],
          height: '100%',
        }}
        src={image.file.url}
      />

      <Box sx={{ position: 'relative' }} ref={imageRef}>
        <GatsbyImage
          objectFit="fill"
          sx={{
            display: ['none', null, 'block'],
            height: ['100%'],
            width: '100%',
            borderRadius: [0, '20px'],
          }}
          image={image.gatsbyImageData}
        />
      </Box>
    </>
  )
}

const LayerImage = ({ layer, key }) => {
  return (
    <>
      <img
        sx={{
          display: ['block', null, 'none'],
          position: ['absolute', null, 'unset'],
          width: ['auto', null, '100%'],
          borderRadius: [0, '20px'],
          height: '100%',
        }}
        src={layer.file.url}
      />
      <GatsbyImage
        sx={{
          display: ['none', null, 'block'],
          position: ['unset', null, 'absolute'],
          top: 0,
          left: 0,
          zIndex: 2 + key,
        }}
        image={layer.gatsbyImageData}
      />
    </>
  )
}

const MapListContainer = (props) => {
  const [listView, setListView] = useState(false)
  return (
    <WidgetContainer
      styles={{
        padding: '0',
        paddingBottom: '5px',
        marginBottom: '0',
        position: 'relative',
        maxWidth: 'large',
        margin: 'auto',
      }}
      flush={false}
    >
      <Flex
        sx={{
          zIndex: 10,
          top: 10,
          right: [10, null, 30],
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          gap: '3px',
        }}
      >
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            p: '6px',
          }}
          variant={listView ? 'primary' : 'secondary'}
          onClick={() => setListView(true)}
        >
          <ListIcon height={15} width={15} />
          List
        </Button>
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            p: '6px',
          }}
          variant={listView ? 'secondary' : 'primary'}
          onClick={() => setListView(false)}
        >
          <BeachMap height={15} width={15} />
          Map
        </Button>
      </Flex>
      {listView ? <List {...props} /> : <Map {...props} />}
    </WidgetContainer>
  )
}

export default MapListContainer
