import React, { useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
import moment from 'moment-timezone'
import { google, ics } from 'calendar-link'
import OrangeActionButton from '../shared/OrangeActionButton'

export const AddToCalendarButtons = ({
  eventTitle,
  eventStreetAddress,
  eventCity,
  eventEnd,
  eventDate,
}) => {
  const [links, setLinks] = useState({})

  useEffect(() => {
    const dets = {
      title: eventTitle,
      location: `${eventStreetAddress}, ${eventCity}`,
      start: moment(eventDate).toISOString(),
      duration: eventEnd
        ? [moment(eventEnd).diff(eventDate, 'hour'), 'hours']
        : [2, 'hours'],
    }
    setLinks({
      ios: ics(dets),
      google: google(dets),
    })
  }, [])

  return (
    <Flex
      sx={{
        flexWrap: ['wrap', 'nowrap'],
        marginBottom: 10,
        gap: '10px',
      }}
    >
      <OrangeActionButton
        href={links.google}
        text="Add to Google calendar"
        target="_blank"
        rel="noopener noreferrer"
      />
      <OrangeActionButton
        href={links.ios}
        text=" Add to IOS calendar"
        target="_blank"
        rel="noopener noreferrer"
      />
    </Flex>
  )
}
