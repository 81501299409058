/** @jsx jsx */
import { jsx } from 'theme-ui'
import WidgetContainer from '../shared/WidgetContainer'

export default function Advertisement({ adImage }) {
  return (
    <WidgetContainer
      styles={{
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img
        sx={{
          width: '100%',
          maxHeight: '150px',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: '10px',
        }}
        src={adImage.file.url}
      />
    </WidgetContainer>
  )
}
