import React from 'react'

function PalmTree(props) {
  const title = props.title || 'palm tree'

  return (
    <svg
      height="48"
      width="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g
        fill="#f7f7f7"
        stroke="#f7f7f7"
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="4"
      >
        <path
          d="M29.457,39.852a35.731,35.731,0,0,0,.837-7.667,35.46,35.46,0,0,0-7.275-21.554L22.576,11A35.5,35.5,0,0,1,25.294,24.64a35.5,35.5,0,0,1-3.059,14.453"
          fill="none"
          stroke="#f7f7f7"
          strokeLinecap="butt"
        />
        <path
          d="M38.006,46A17.551,17.551,0,0,0,9.994,46Z"
          fill="none"
          stroke="#f7f7f7"
        />
        <path d="M11,27,22.909,10.741C13.757,12,7,16,11,27Z" fill="none" />
        <path d="M42,22,22.909,10.741S41,5,42,22Z" fill="none" />
        <path d="M22.909,10.741,8,8.991S18-1.061,22.909,10.741Z" fill="none" />
        <path d="M22.909,10.741,37,4S24-3.35,22.909,10.741Z" fill="none" />
      </g>
    </svg>
  )
}

export default PalmTree
