import React from 'react'

export default function SMSAlerts(props) {
  const title = props.title || 'SMS Alerts'

  return (
    <svg
      height={props.height || '48'}
      width={props.width || '48'}
      {...props}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="#F7F7F7">
        <path d="M40 4H8C5.79 4 4.02 5.79 4.02 8L4 44l8-8h28c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zM26 28h-4v-4h4v4zm0-8h-4v-8h4v8z" />
      </g>
    </svg>
  )
}

