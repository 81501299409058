/** @jsx jsx */
import { jsx } from 'theme-ui'

export default function Pier({
  image,
  offsetTop,
  offsetLeft,
  imageRef,
  ratio = 1,
}) {
  return (
    <img
      src={image}
      sx={{
        maxHeight: '35%',
        position: 'absolute',
        top: `${offsetTop * ratio * 100}%`,
        left: imageRef?.current && imageRef.current.clientWidth * offsetLeft,
      }}
    />
  )
}
