/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'

import { arc } from 'd3-shape'
import { scaleLinear } from 'd3-scale'

const Gauge = ({ activityType, hazardLevels, towerData, waveHeight }) => {
  const isWave = activityType === 'wave height'

  hazardLevels = hazardLevels.sort((a, b) => (a.min > b.min ? 1 : -1))

  const totalCountLabel =
    'total' + activityType.charAt(0).toUpperCase() + activityType.slice(1)

  const scaleMin = hazardLevels[0].min
  const scaleMax = hazardLevels[hazardLevels.length - 1].max
  const innerRadius = 0.75
  const outerRadius = 1

  const value = isWave ? waveHeight : towerData[totalCountLabel]

  const towerLabel =
    hazardLevels.find(
      (hazardLevel) => value >= hazardLevel.min && value <= hazardLevel.max
    )?.name || hazardLevels[hazardLevels.length - 1].name

  const backgroundArc = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(0)()

  function getAngle(value) {
    const percentScale = scaleLinear()
      .domain([scaleMin, scaleMax])
      .range([0, 1])
    const percent = percentScale(value)

    const angleScale = scaleLinear()
      .domain([0, 1])
      .range([-Math.PI / 2, Math.PI / 2])
      .clamp(true)
    return angleScale(percent)
  }

  let percentage = Math.round(
    (value / hazardLevels[hazardLevels.length - 1].max) * 100
  )

  if (percentage > 100) {
    percentage = 100
  }

  const markerLocation = getCoordsOnArc(getAngle(value), 1 - (1 - 0.75) / 2)

  return (
    <>
      <div
        sx={{
          position: 'relative',
        }}
      >
        {isNaN(value) && (
          <p
            sx={{
              width: '100%',
              position: 'absolute',
              top: '0',
              textAlign: 'center',
            }}
          >
            Unavailable
          </p>
        )}
        <svg
          sx={{
            width: ['11em'],
            visibility: isNaN(value) ? 'hidden' : null,
          }}
          viewBox={[-1, -1, 2, 1].join(' ')}
          style={{ overflow: 'visible' }}
        >
          <defs>
            <filter id="shadow" width="150%" height="150%">
              <feDropShadow
                dx="0.02"
                dy="0.02"
                stdDeviation="0.015"
                floodColor="rgba(0, 0, 0, 0.25)"
              />
            </filter>
          </defs>
          <path d={backgroundArc} fill={'#dbdbe7'} />
          {hazardLevels
            .slice(0)
            .reverse()
            .map((item, index) => {
              return (
                <path
                  key={index}
                  d={arc()
                    .innerRadius(innerRadius)
                    .outerRadius(outerRadius)
                    .startAngle(getAngle(item.min - 1))
                    .endAngle(getAngle(item.max))
                    .cornerRadius(0)()}
                  fill={item.color}
                />
              )
            })}

          {!isNaN(value) && (
            <circle
              cx={markerLocation[0]}
              cy={markerLocation[1]}
              r="0.15"
              stroke={'#EAEDFF'}
              strokeWidth="0.01"
              fill={'rgba(255, 255, 255, 0.74)'}
              filter="url(#shadow)"
            />
          )}
          {!isNaN(value) && (
            <circle
              cx={markerLocation[0]}
              cy={markerLocation[1]}
              r="0.1"
              fill={'#FFFFFF'}
            />
          )}
        </svg>
        <div
          sx={{
            position: 'absolute',
            bottom: '2px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <h3
            sx={{
              visibility: isNaN(value) ? 'hidden' : null,
              width: '60%',
              margin: 'auto',
              fontSize: ['13px', '16px'],
            }}
          >
            {towerLabel}
          </h3>
        </div>
      </div>
    </>
  )
}

export default Gauge

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
]
