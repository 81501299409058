import React from 'react'

export default function ChevronLeft({ height, width, styles, ...props }) {
  return (
    <svg
      style={{ ...styles }}
      width={width || '30'}
      height={height || '30'}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.9999 13.4L11.3999 12L7.3999 8L11.3999 4L9.9999 2.6L4.5999 8L9.9999 13.4Z"
        fill="#E56846"
      />
    </svg>
  )
}
