/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react'
import { Flex, jsx } from 'theme-ui'
import InfoCard from '../../shared/InfoCard'
import FlagIcon from '../../shared/FlagIcon'

export default function TowerCardModalContent({
  towerData,
  description,
  disclaimer,
  title,
  hazardLevels,
  activityType,
  waveHeight,
  beachOverride,
}) {
  const sortedTowers = hazardLevels.map((hazardLevel) => {
    if (activityType === 'wave height') {
      return {
        title: hazardLevel.name,
        hazardColor: hazardLevel.color,
        towers: towerData.towers.filter((tower) => {
          if (
            tower.override === hazardLevel.name ||
            beachOverride === hazardLevel.name
          )
            return true
          return waveHeight >= hazardLevel.min && waveHeight <= hazardLevel.max
        }),
      }
    } else {
      return {
        title: hazardLevel.name,
        hazardColor: hazardLevel.color,
        towers: towerData.towers.filter((tower) => {
          if (
            tower.override === hazardLevel.name ||
            beachOverride === hazardLevel.name
          )
            return true
          return (
            tower[activityType] >= hazardLevel.towerMin &&
            tower[activityType] <= hazardLevel.towerMax
          )
        }),
      }
    }
  })

  return (
    <>
      <InfoCard
        title={title}
        description={description}
        disclaimer={disclaimer}
        containerStyles={{
          boxShadow: 'none',
          padding: ['15px 0px 20px', '0px 12.5% 10px'],
          borderBottom: ['none', '1px solid rgb(229, 229, 229)'],
          borderRadius: ['10px', '0'],
          '& div:first-of-type': {
            justifyContent: ['flex-start', 'center'],
          },
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        {sortedTowers.map((section, index) => {
          return (
            <InfoCard
              key={index}
              title={section.title}
              titleIcon={<FlagIcon color={section.hazardColor} />}
              iconColor={section.hazardColor}
              bullets={section.towers}
              bulletColumns={4}
              isTowerDetailed
              containerStyles={{
                margin: ['8px auto', 'none'],
                borderWidth: ['0px', '2px'],
                borderStyle: 'solid',
                borderColor: ['none', 'gray600'],
                boxShadow: 'none',
                padding: ['20px 0px', '10px 12.5%'],
              }}
            >
              {!section.towers.length && (
                <p sx={{ margin: 0, variant: 'styles.p' }}>
                  There are currently no towers in the {section.title} category
                </p>
              )}
            </InfoCard>
          )
        })}
      </Flex>
    </>
  )
}
