/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import { ModalButton } from '../../shared/ModalButton'
import AgencyPageList from './AgencyPageList'

export default function AgencyPageLink({ title, rules, currentRule }) {
  const isShowMore = currentRule.id === 'showMore'

  return isShowMore ? (
    <ModalButton
      modalStyles={{
        height: ['96vh', '90vh'],
        margin: '0px',
        padding: '0%',
      }}
      modalContentStyles={{
        height: '100%',
        padding: '0px',
      }}
      buttonStyles={{ width: 'auto' }}
      buttonVariant="link"
      buttonText={currentRule.title + '...'}
      renderModalContent={() => {
        return <AgencyPageList title={title} rules={rules} />
      }}
    />
  ) : (
    <Link
      target={currentRule.externalUrl && '_blank'}
      to={currentRule.externalUrl || currentRule.slug}
    >
      {currentRule?.title}
    </Link>
  )
}
