import React from 'react'
import Wave from 'react-wavify'
import { Box, Flex } from 'theme-ui'

export default function DataLoading() {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 5,
          left: 0,
          background: `#000`,
          opacity: '.2',
        }}
      />
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: ['20%', '20%', '20%', '30%'],
          left: 0,
          right: 0,
          width: '100%',
          zIndex: 5,
        }}
      >
        <Box
          sx={{
            p: '5px',
            bg: 'white',
            textAlign: 'center',
            borderRadius: '4px',
            width: '100px',
            height: '100px',
          }}
        >
          Surfs up...
          <Wave
            fill="#b1dbfc"
            style={{ height: '70px' }}
            options={{
              points: 4,
              speed: 0.4,
              amplitude: 10,
              height: 5,
            }}
          >
            {/* Example adapted from https://developer.mozilla.org/en-US/docs/Web/SVG/Element/mask */}
          </Wave>
        </Box>
      </Flex>
    </>
  )
}
