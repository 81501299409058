/** @jsx jsx */
import React, { forwardRef } from 'react'
import { Box, jsx } from 'theme-ui'

export const CustomCard = forwardRef(
  ({ flush = false, styles, children, ...props }, ref) => {
    return (
      <Box
        {...props}
        ref={ref}
        sx={{
          backgroundColor: 'lightText',
          width: '100%',
          borderRadius: ['10px'],
          padding: !flush ? ['20px 10px', '20px'] : '0px',
          boxShadow: [
            '4px 4px 8px rgba(234, 237, 255, 0.25);',
            '4px 4px 8px #EAEDFF',
          ],
          ...styles,
        }}
      >
        {children}
      </Box>
    )
  }
)

export default forwardRef((props) => {
  const { isFAQModal, styles } = props

  return (
    <CustomCard
      styles={{
        height: isFAQModal ? '100%' : 'auto',
        position: 'relative',
        ...styles,
      }}
      {...props}
    >
      {props.children}
    </CustomCard>
  )
})
