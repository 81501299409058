import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'

export const BgImg = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`
export const DeviceContext = React.createContext()
export const TowerContext = React.createContext()
export const WeatherContext = React.createContext()
