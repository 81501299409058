/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import { ModalButton } from '../../shared/ModalButton'

const keyContainerStyles = {
  alignItems: 'center',
  flexBasis: ['50%', null, 'auto'],
  marginRight: [null, null, null, '12px'],
}

function TowerKey({ icon, name, color, ...rest }) {
  return (
    <Flex sx={{ ...keyContainerStyles }} {...rest}>
      {!color ? (
        <img
          sx={{ width: '25px', height: '25px', marginRight: '2px' }}
          src={icon.file.url}
        />
      ) : (
        <Box
          sx={{
            p: '5px',
            bg: color,
            mr: '10px',
            width: '20px',
            height: '20px',
          }}
        />
      )}
      <p aria-label={name} sx={{ margin: '0', fontSize: '16px' }}>
        {name}
      </p>
    </Flex>
  )
}

export default function HazardMap({ towerHazardRatings }) {
  const key = towerHazardRatings
    .concat([
      { color: 'pink', name: 'Active/Upcoming Event', includeOnMapKey: true },
      { color: 'closed', name: 'Closed', includeOnMapKey: false },
    ])
    .filter((th) => th.includeOnMapKey)
    .map((th, key) => <TowerKey key={key} {...th} />)

  return (
    <>
      <ModalButton
        modalHeader="Map Key"
        buttonText={'What do the colors mean?'}
        modalWrapperStyles={{
          width: '100%',
        }}
        buttonStyles={{
          bg: 'white',
          fontSize: '14px',
          display: ['block', 'none'],
          color: 'text',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'black',
          textDecoration: 'underline',
          maxWidth: ['none', '250px'],
          width: '100%',
        }}
        modalStyles={{
          height: '96vh',
        }}
        modalContentStyles={{
          height: 'auto',
        }}
        renderModalContent={() => (
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {key}
          </Flex>
        )}
      />

      <Flex
        sx={{
          display: ['none', 'flex'],
          width: '100%',
          py: 2,
          px: 3,
          flexWrap: ['wrap', null, 'nowrap'],
          flexDirection: 'row',
          gap: 2,
        }}
      >
        <strong sx={{ width: ['100%', null, 'auto'], minWidth: '140px' }}>
          Hazard Key:
        </strong>
        <Box
          sx={{
            display: ['grid', null, 'flex'],
            gridTemplateColumns: ['repeat(3, 1fr)'],
            gridAutoFlow: 'row',
            width: ['100%', null, 'auto'],
            gap: 2,
          }}
        >
          {key}
        </Box>
      </Flex>
    </>
  )
}
