/** @jsx jsx */
// eslint-disable-next-line no-unused-vars

import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { jsx, Flex, Progress } from 'theme-ui'
import theme from '../../../gatsby-plugin-theme-ui'
import { PulsingIndicator } from '../../shared/PulsingIndicator'

export default function ParkingLabel({
  imageRef,
  ratio = 1,
  offsetTop,
  parkingInfoUrl,
  offsetLeft,
  id,
  capacity,
  name,
}) {
  let statusColor = 'lightgrey'
  let avg = null
  if (capacity === '0 - 25% Full' || capacity === '25 - 50% Full') {
    statusColor = 'moderateRisk'
    avg = 0.25
  } else if (capacity === '50 - 75% Full') {
    avg = 0.6
    statusColor = 'caution'
  } else if (capacity === '75 - 100% Full') {
    statusColor = 'highRisk'
    avg = 0.8
  }

  return (
    <>
      <ReactTooltip
        id={id}
        clickable
        delayHide={1000}
        sx={{
          bg: 'white',
          color: 'text',
          width: '120px',
        }}
        aria-haspopup="true"
      >
        <a
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mb: '4px',
          }}
          href={parkingInfoUrl}
          target="__Blank"
        >
          {name}{' '}
        </a>
        {capacity}
        <Progress
          bg={'background'}
          color={statusColor}
          sx={{ mt: 10 }}
          max={1}
          value={avg}
        >
          {capacity}
        </Progress>
      </ReactTooltip>
      <Flex
        sx={{
          position: 'absolute',
          top: `${offsetTop * ratio * 100}%`,
          left: `${imageRef?.current?.clientWidth * offsetLeft}px`,

          cursor: 'pointer',
          padding: '3px',

          // minWidth: imageRef?.current?.clientWidth * width,
        }}
        data-tip
        data-for={id}
      >
        <PulsingIndicator color={theme.colors[statusColor]} />
      </Flex>
    </>
  )
}
