/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, Input, Flex, Box } from 'theme-ui'
import QuestionMark from '../../../icons/question-mark.svg'
import OrangeActionButton from '../../shared/OrangeActionButton'
import theme from '../../../gatsby-plugin-theme-ui'
import ChevronRight from '../../../icons/chevron-right'
import { Link } from 'gatsby'

export default function AgencyPageList({ title, rules }) {
  const [input, setInput] = useState('')
  const [search, setSearch] = useState('')

  const handleOnChange = (event) => {
    setInput(event.target.value.toLowerCase())
    setSearch(event.target.value.toLowerCase())
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSearch(input)
  }
  return (
    <Flex
      sx={{
        minWidth: '100%',
        padding: ['0 8px', '0 90px'],
        maxHeight: '100%',
        gap: '20px',
        flexDirection: 'column',
      }}
    >
      <div sx={{ marginTop: '67px' }}>
        <h1 sx={{ textAlign: 'center', margin: '0', fontSize: [5, 6] }}>
          {title}
        </h1>
        <form
          sx={{
            display: 'flex',
            margin: ['10px 0', '20px 0'],
          }}
          onSubmit={(event) => handleSubmit(event)}
        >
          <Input
            sx={{
              borderLeft: `1px solid ${theme.colors.iconCircle}`,
              borderTop: `1px solid ${theme.colors.iconCircle}`,
              borderBottom: `1px solid ${theme.colors.iconCircle}`,
              borderRight: 'none',
              borderRadius: '10px 0 0 10px',
              padding: '0 30px',
              display: 'inline-block',
              boxSizing: 'content-box',
              height: '48px',
            }}
            placeholder="Please type here"
            type="text"
            onChange={handleOnChange}
          />
          <OrangeActionButton
            buttonType="submit"
            text="Search"
            styles={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: ['40%', '25%'],
              padding: 0,
              borderRadius: '0 10px 10px 0',
              height: '50px',
              border: `1px solid ${theme.colors.iconCircle}`,
            }}
          />
        </form>
      </div>
      <Box
        sx={{
          marginTop: '10px',
          maxHeight: '100%',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {rules
          .filter((rule) => rule.title.toLowerCase().includes(search))
          .map((rule) => {
            return (
              <Link to={rule.slug}>
                <Box
                  as="button"
                  key={rule.id}
                  id={rule.title}
                  sx={{
                    width: '100%',
                    padding: ['0 10px', '0 20px'],
                    margin: '0 0 10px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'disclaimer',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                  }}
                >
                  <div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: ['75%', '85%'],
                    }}
                  >
                    <img
                      src={rule.icon ? rule.icon?.file?.url : QuestionMark}
                      sx={{
                        width: '30px',
                        height: '30px',
                        marginRight: ['10px', '15px'],
                      }}
                    />
                    <h4 sx={{ textAlign: 'left', margin: '12px 0px' }}>
                      {rule.title}
                    </h4>
                  </div>
                  <ChevronRight />
                </Box>
              </Link>
            )
          })}
        {rules.filter((rule) => rule.title.toLowerCase().includes(search))
          .length === 0 && <p>There are no matches</p>}
      </Box>
    </Flex>
  )
}
