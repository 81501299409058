/** @jsx jsx */
import React, { Fragment, useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { jsx, Flex, Box } from 'theme-ui'
import Container from '../components/Container'
import SEO from '../components/SEO'

import Advertisement from '../components/main/Advertisement'
import { useWindowSize } from '../hooks/useWindowSize'
import { useSearchParams } from '../hooks/useSearchParams'
import { merge, find, map } from 'lodash'
import { useLocation } from 'react-use'
import { TowerContext, WeatherContext } from '../utils'
import { GenerateBlock } from '../components/GenerateBlocks'
import MainNavbar from '../components/MainNavbar'
import BeachNavigation from '../components/main/BeachNavigation'
import { GradientBox } from '../components/shared/GradientBox'
// import { useQueryCustom } from '../hooks/useQueryCustom'
import { useQuery, gql } from '@apollo/client'

import getReportUrgencyColor from '../utils/getReportUrgencyColor'
import LiveFeedModal from '../components/LiveFeedModalButton'
import NWSAlert from '../components/NWSAlert'
import useGetWeather from '../hooks/useGetWeather'
import MapListContainer from '../components/main/Map/Map'
import DisclaimerModal from '../components/DisclaimerModal'
import useFilterBulletinByPath from '../hooks/useFilterBulletinByPath'

const GET_WATCHTOWER_DATA = gql`
  query ReadDashboardData($agencyId: ID) {
    readAgencyDashboardData(agencyId: $agencyId) {
      id
      globalOverride
      latitude
      longitude
      noaaTidesStation
      surflineSpotId
      timezone

      publicBulletins {
        id
        message
        title
        videoUrl
        createdAt
        urgency
        pinOnDashboard
        hideTimestamp
        showOnPaths
      }
      towers {
        towerId
        override
        overrideReason
        latitude
        longitude
        platformTowerName
        stingrays
        jellyfish
        rescues
        isClosed
        hasEvent
        hideFromPublic
      }
    }
  }
`

const BeachTemplate = ({ data }) => {
  const { device } = useSearchParams()
  const windowSize = useWindowSize()

  const location = useLocation()
  const { allContentfulLocationFeature } = data

  const {
    name,
    slug,
    id,
    partnerAdImage,
    watchtowerAgencyId,
    hideSubscribeButton,
    agencyLogo,
    blocks = [],
    towers,
    piers,
    parking = [],
    qrCode,
    leftNeighboringBeaches,
    rightNeighboringBeaches,
    mapBackgroundImage,
    agencyName,
    towerHazardRatings,
    liveFeeds,
    noaaZoneCodes,
    customWeatherLatLng,
    customWeatherNoaaState,
    customWeatherSurflineSpotId,
    customWeatherNoaaTideStation,
    timezone,
    featureLists,
    hideShameCard,
    layers,
  } = data.individual.nodes[0]

  const beachWebsiteLink = location.origin + `/${slug}`

  const dailyReportRef = useRef()
  // initialize towerData with the data from Contentful before
  // combining w API data
  const [towerData, setTowerData] = useState({ towers })
  const [parkingData, setParkingData] = useState(parking)
  const bulletinsForThisPath = useFilterBulletinByPath(
    towerData?.publicBulletins || []
  )
  const urgentBulletin = bulletinsForThisPath?.find((pb) => pb.pinOnDashboard)

  const { data: queryData, loading: towerDataLoading } = useQuery(
    GET_WATCHTOWER_DATA,
    {
      variables: {
        agencyId: watchtowerAgencyId,
      },
      skip: !watchtowerAgencyId,
      pollInterval: device === 'tv' ? 120000 : null,

      onCompleted: (data) => {
        const apiData = data.readAgencyDashboardData
        if (!apiData) return

        const combinedTowerData = map(towers, function (item) {
          return merge(
            item,
            find(apiData.towers, {
              towerId: item.towerId,
            })
          )
        })

        function getTotalCounts(array, value) {
          return array.reduce(function (sum, obj) {
            return sum + (obj[value] || 0)
          }, 0)
        }

        const totalStingrays = getTotalCounts(combinedTowerData, 'stingrays')
        const totalJellyFish = getTotalCounts(combinedTowerData, 'jellyfish')
        const totalRescues = getTotalCounts(combinedTowerData, 'rescues')

        const combinedParkingData = map(parking, function (item) {
          return merge(
            item,
            find(apiData.parkingStatuses, { id: item.parkingLotId })
          )
        })

        const towersUpdated = {
          ...apiData,
          towers: combinedTowerData,
          totalStingrays,
          totalJellyFish,
          totalRescues,
        }

        setTowerData(towersUpdated)
        setParkingData(combinedParkingData)
      },
    }
  )

  const queryRes = queryData?.readAgencyDashboardData

  const surflineSpotId = customWeatherSurflineSpotId || queryRes?.surflineSpotId
  const latitude = customWeatherLatLng?.lat || queryRes?.latitude
  const longitude = customWeatherLatLng?.lon || queryRes?.longitude
  const noaaTidesStation =
    customWeatherNoaaTideStation || queryRes?.noaaTidesStation
  const agencyTz = timezone || queryRes?.timezone

  const paramData = {
    latitude,
    longitude,
    ...(customWeatherNoaaState && {
      noaaSurfState: customWeatherNoaaState?.split('_')[0],
      noaaSurftStateDirection: customWeatherNoaaState?.split('_')[1],
    }),
    ...(surflineSpotId && {
      surflineSpotId,
    }),
    noaaTidesStation,
    agencyTz,
  }

  const { weatherData, weatherDataLoading } = useGetWeather(
    paramData,
    !watchtowerAgencyId || queryRes
  )

  const filterAndSortColumns = (blocks, columnNumber) => {
    return blocks
      .filter((block) => block.desktopColumnNumber === columnNumber)
      .sort((a, b) => (a.desktopPlaceNumber > b.desktopPlaceNumber ? 1 : -1))
  }

  const webColumn1 = filterAndSortColumns(blocks, 1)
  const webColumn2 = filterAndSortColumns(blocks, 2)

  const mapProps = {
    mapBackgroundImage: mapBackgroundImage,
    beachName: name,
    piers,
    towers: towerData,
    aquaticRiskLevels: towerHazardRatings,
    weatherData: weatherData?.[0],
    parking: parkingData,
    watchtowerAgencyId,
    towerHazardRatings,
    agencyLogo,
    hideSubscribeButton,
    liveFeeds,
    layers,
  }

  const generateBlockProps = {
    name,
    aquaticRiskLevels: towerHazardRatings,
    agencyLogo,
    agencyName,
    qrCode,
    beachWebsiteLink,
    tz: timezone || towerData.timezone,
    featureLists,
    hideSubscribeButton,
    hideShameCard,
    featureMenu: allContentfulLocationFeature?.nodes || [],
  }

  let mobileBlocks = []

  if (windowSize.tabletDown) {
    mobileBlocks = blocks.map((i) => (
      <GenerateBlock
        dailyReportRef={dailyReportRef}
        watchtowerAgencyId={watchtowerAgencyId}
        key={i.id}
        {...{ block: i }}
        {...generateBlockProps}
      />
    ))
    partnerAdImage &&
      mobileBlocks.splice(1, 0, <Advertisement adImage={partnerAdImage} />)

    mobileBlocks.splice(
      1,
      0,
      <NWSAlert agencyId={watchtowerAgencyId} zoneCodes={noaaZoneCodes} />
    )
  }

  return (
    <Fragment>
      <SEO
        title={name + ' | ' + 'Safe Beach Day'}
        description={`${name} safety and visitor information.  Find up to date safety information, conditions and FAQs.  The best source of information for beachgoers.`}
        image={mapBackgroundImage.file.url}
      />

      <TowerContext.Provider value={{ towerData }}>
        <WeatherContext.Provider
          value={{
            weatherData: weatherData?.[0],
            weatherDataLoading: towerDataLoading || weatherDataLoading,
          }}
        >
          <Container
            sx={{
              position: 'relative',
              minHeight: '100vh',
            }}
          >
            {device !== 'tv' && (
              <MainNavbar
                mode="relative"
                iconOnMobile
                hideIndexes={[3]}
                middleContent={
                  <BeachNavigation
                    leftNeighboringBeaches={leftNeighboringBeaches || []}
                    rightNeighboringBeaches={rightNeighboringBeaches || []}
                    slug={slug}
                    beach={{
                      slug: slug,
                      name: name,
                      id: id,
                    }}
                  />
                }
              />
            )}

            {(urgentBulletin || (data.contentfulAlert && device !== 'tv')) && (
              <Link
                sx={{ textDecoration: 'none', width: '100vw' }}
                to={!urgentBulletin && data?.contentfulAlert?.link}
              >
                <GradientBox
                  onClick={() => {
                    if (urgentBulletin && dailyReportRef) {
                      dailyReportRef?.current?.scrollIntoView()
                    }
                  }}
                  sx={{
                    fontSize: '14px',
                    textAlign: 'center',
                    padding: '10px',
                    borderRadius: '0px',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    border: 'none',
                    margin: '0px',
                    width: '100%',
                    justifyContent: 'center',
                    textTransform: 'capitalize',
                    color: urgentBulletin && 'white',
                    cursor: 'pointer',
                    zIndex: 2,
                    background:
                      urgentBulletin &&
                      getReportUrgencyColor(urgentBulletin.urgency),
                  }}
                >
                  <Box sx={{ maxWidth: 'containerMaxWidth' }}>
                    {urgentBulletin?.title
                      ? urgentBulletin?.title
                      : data?.contentfulAlert?.text}
                  </Box>
                </GradientBox>
              </Link>
            )}
            {liveFeeds && device !== 'tv' && (
              <Box
                sx={{
                  position: 'fixed',
                  zIndex: 100,
                  right: 15,
                  bottom: 15,
                }}
              >
                <LiveFeedModal liveFeeds={liveFeeds} />
              </Box>
            )}
            {windowSize.tabletDown ? (
              <Flex
                sx={{
                  width: '100%',
                  margin: 'auto',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  alignContent: 'flex-start',
                  gap: '16px',
                  marginBottom: 4,
                  position: 'relative',
                }}
              >
                <MapListContainer
                  loading={towerDataLoading || weatherDataLoading}
                  {...mapProps}
                />

                {mobileBlocks}
              </Flex>
            ) : (
              <div
                sx={{
                  width: '98%',
                  margin: 'auto',
                  marginBottom: 4,
                }}
              >
                <Box sx={{ marginBottom: '16px', position: 'relative' }}>
                  <MapListContainer
                    loading={towerDataLoading || weatherDataLoading}
                    {...mapProps}
                  />
                </Box>
                <Flex sx={{ maxWidth: 'large', margin: 'auto' }}>
                  <Flex
                    sx={{
                      width: '60%',
                      marginRight: '0.5%',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'flex-start',
                      alignContent: 'flex-start',
                      gap: '16px',
                    }}
                  >
                    {webColumn1.map((i) => (
                      <GenerateBlock
                        dailyReportRef={dailyReportRef}
                        watchtowerAgencyId={watchtowerAgencyId}
                        key={i.id}
                        {...{ block: i }}
                        {...generateBlockProps}
                      />
                    ))}
                  </Flex>
                  <Flex
                    sx={{
                      width: '40%',
                      marginLeft: '0.5%',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'flex-start',
                      alignContent: 'flex-start',
                      gap: '16px',
                    }}
                  >
                    {partnerAdImage && (
                      <Advertisement adImage={partnerAdImage} />
                    )}
                    <NWSAlert
                      zoneCodes={noaaZoneCodes}
                      agencyId={watchtowerAgencyId}
                      lat={latitude}
                      lng={longitude}
                    />
                    {webColumn2.map((i) => (
                      <GenerateBlock
                        watchtowerAgencyId={watchtowerAgencyId}
                        dailyReportRef={dailyReportRef}
                        key={i.id}
                        {...{ block: i }}
                        {...generateBlockProps}
                      />
                    ))}
                  </Flex>
                </Flex>
              </div>
            )}
          </Container>
        </WeatherContext.Provider>
      </TowerContext.Provider>
      <DisclaimerModal />
    </Fragment>
  )
}

export const query = graphql`
  query Beach($slug: String!) {
    contentfulAlert(live: { eq: true }) {
      text
      link
    }
    allContentfulLocationFeature {
      nodes {
        title
        category
        configKey
        icon {
          file {
            url
          }
        }
      }
    }
    individual: allContentfulBeach(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        name
        hideShameCard
        slug
        noaaZoneCodes
        customWeatherNoaaState
        customWeatherNoaaTideStation
        customWeatherSurflineSpotId
        timezone
        customWeatherLatLng {
          lon
          lat
        }
        watchtowerAgencyId
        hideSubscribeButton
        featureLists {
          ... on ContentfulLocationFeaturesActivitiesConfig {
            ...LocationFeaturesActivities
          }
          ... on ContentfulLocationFeaturesAmenitiesConfig {
            ...LocationFeaturesAmenities
          }
          ... on ContentfulLocationFeaturesHazardsConfig {
            ...LocationFeaturesHazards
          }
          ... on ContentfulLocationFeaturesProhibitionsConfig {
            ...LocationFeaturesProhibitions
          }
        }
        leftNeighboringBeaches {
          id
          name
          slug
        }
        rightNeighboringBeaches {
          id
          name
          slug
        }
        qrCode {
          file {
            url
          }
        }
        partnerAdImage {
          file {
            url
          }
        }
        mapBackgroundImage {
          gatsbyImageData(quality: 65, placeholder: BLURRED)
          file {
            url
          }
        }
        layers {
          gatsbyImageData(quality: 65, placeholder: NONE)
          title
          file {
            url
          }
        }
        agencyLogo {
          gatsbyImageData(aspectRatio: 1)
          file {
            url
          }
        }
        agencyName
        towerHazardRatings {
          name
          color
          incidentType
          min
          max
          towerMin
          towerMax
          includeOnMapKey
          textTopOffset
          cantTowerIcon
          icon {
            file {
              url
            }
          }
        }
        piers {
          pierName
          offsetTop
          offsetLeft
          pierImage {
            file {
              url
            }
          }
        }
        towers {
          towerId
          towerName
          offsetTop
          offsetLeft
          description
          indicatorOnly
          customCloseIcon {
            description
            file {
              url
            }
          }
          customOpenIcon {
            description
            file {
              url
            }
          }
        }
        liveFeeds {
          id
          name
          url
        }
        parking {
          id
          parkingLotId
          parkingInfoUrl
          parkingLotName
          offsetTop
          offsetLeft
          width
        }

        blocks {
          __typename
          ... on ContentfulBulletin {
            id
            title
            desktopColumnNumber
            desktopPlaceNumber
          }
          ... on ContentfulTitleCard {
            id
            title
            desktopColumnNumber
            desktopPlaceNumber
          }
          ... on ContentfulOverallRiskCard {
            id
            title
            description {
              raw
            }
            disclaimer {
              raw
            }
            bulletColumns
            activityType
            dataDisplay
            hazardLevels {
              name
              color
              min
              max
              # fallbackMax
              # fallbackMin
              towerMax
              towerMin
            }
            desktopColumnNumber
            desktopPlaceNumber
          }
          ## Rules === AgencyPages now
          ... on ContentfulRulesSummary {
            id
            title

            rules {
              id
              slug
              title
              externalUrl
              icon {
                file {
                  url
                }
              }
            }
            desktopColumnNumber
            desktopPlaceNumber
          }
          ... on ContentfulOverview {
            id
            descriptionTitle
            description {
              id
              childMarkdownRemark {
                html
              }
            }
            schedules {
              title
              hours {
                ... on ContentfulScheduleHours {
                  title
                  open
                  close
                  closed
                }
              }
            }
            desktopColumnNumber
            desktopPlaceNumber
          }
          ... on ContentfulEventWidget {
            id
            title
            description {
              raw
            }
            events {
              id
              eventTitle
              eventDate
              eventEnd
              eventEnd
              eventStreetAddress
              eventCity
              eventDescription {
                raw
              }
            }
            desktopColumnNumber
            desktopPlaceNumber
          }
        }
      }
    }
  }
`

export const FeaturesActivitiesConfig = graphql`
  fragment LocationFeaturesActivities on ContentfulLocationFeaturesActivitiesConfig {
    americanFootball
    baseball
    basketball
    bicycles
    drones
    electricScooter
    fishing
    golf
    kiteboarding
    scuba
    skateboarding
    snorkeling
    beachCombing
    kayaking
    soccer
    spearFishing
    surfing
    swimming
    tennis
    volleyball
    windsurfing
    dogs
    camping
    catamaran
    kiteFlying
    additionalInfo {
      id
      configKey
      info {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const FeaturesAmenitiesConfig = graphql`
  fragment LocationFeaturesAmenities on ContentfulLocationFeaturesAmenitiesConfig {
    bbqGrills
    beachAccess
    boatLaunch
    busStop
    cellService
    dogPark
    electricCharging
    firePits
    fiveGNetwork
    bikeRacks
    fourGNetwork
    freeEntry
    freeParking
    waterStation
    lockers
    boating
    meteredParking
    picnic
    playground
    rentals
    restaurantsNearby
    restrooms
    showers
    taxiZone
    threeGNetwork
    trainStation
    wiFi
    leashedDogs
    snackShack
    gearRental
    adaParking
    accessibilityMat
    beachBadge
    handicapAccessible
    communityCenter
    beachTagRequired
    additionalInfo {
      id
      configKey
      info {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const FeaturesHazardsConfig = graphql`
  fragment LocationFeaturesHazards on ContentfulLocationFeaturesHazardsConfig {
    deepWater
    gutters
    heavyShoreBreak
    highSurf
    jellyfish
    largeWaves
    ripCurrents
    bluffs
    shallowWater
    sharks
    sharpCoral
    slipperyRocks
    stingrays
    strongCurrents
    fallingDebris
    submergedObjects
    suddenDropOff
    hikingHazard
    walkingHazard
    runningHazard
    swiftWater
    coldWater
    marineLife
    additionalInfo {
      id
      configKey
      info {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const FeaturesProhibitionsConfig = graphql`
  fragment LocationFeaturesProhibitions on ContentfulLocationFeaturesProhibitionsConfig {
    noAlcohol
    noAmplifiedMusic
    noCamping
    noCarsOnSand
    noCliffJumping
    noClimbing
    noDiving
    noDogs
    noDrones
    noElectricBikes
    noFirePits
    noFirearms
    noTents
    noLittering
    noPierJumping
    noFireworks
    noFishing
    noHoleDigging
    noMotorizedCrafts
    noMotorizedScooters
    noSkateboarding
    noSmoking
    noSpearFishing
    noSurfing
    noSwimming
    noVehicles
    noVending
    noFood
    keepOffSandDunes
    keepOffRockJettiesGroins
    noGlass
    noFeedingBirds
    noAnimals
    noTakeTidePools
    noSurfingInProtectedSwimArea
    additionalInfo {
      id
      configKey
      info {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default BeachTemplate
