/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { jsx, Grid, Flex, Box } from 'theme-ui'
import WidgetContainer from '../../shared/WidgetContainer'
import OverviewBullet from './OverviewBullet'
import TideWidget from './TideWidget'
import moment from 'moment'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { SubscribeButton } from '../../shared/SubscribeButton'
import CollapsibleContent from '../../CollapsibleContent'
import { ModalButton } from '../../shared/ModalButton'
import { groupBy } from 'lodash'
import useGenerateLocationRiskInformation from '../../../hooks/useGenerateLocationRiskInformation'
import Surfer from '../../../icons/Surfer'
import InfoIcon from '../../../icons/InfoIcon'
import CheckboxUnchecked from '../../../icons/CheckboxUnchecked'
import IconCircle from '../../shared/IconCircle'
import theme from '../../../gatsby-plugin-theme-ui'

export default function Overview({
  weatherData,
  beachName,
  data,
  tz,
  aquaticRiskLevels,
  globalOverride,
  watchtowerAgencyId,
  agencyLogo,
  featureLists,
  featureMenu,
  hideSubscribeButton,
  loading,
}) {
  // const weatherIcons = useStaticQuery(GET_WEATHER_ICONS)
  const { device } = useSearchParams()
  // const [forecast, setForecast] = useState(null)
  const [airTemperature, setAirTemperature] = useState(null)
  const [uvIndex, setUvIndex] = useState(null)
  const [waterTemperature, setWaterTemperature] = useState(null)
  const [waveHeight, setWaveHeight] = useState(null)
  const [windSpeed, setWindSpeed] = useState(null)
  const [windDirection, setWindDirection] = useState(null)
  const [tideData, setTideData] = useState(null)

  const flattenedConfigFeatures = featureLists?.reduce(
    (flattenedFeatures, featureList) => {
      for (const [key, value] of Object?.entries(featureList)) {
        flattenedFeatures[key] =
          key !== 'additionalInfo'
            ? value
            : [...(flattenedFeatures[key] ?? []), ...(value ?? [])]
      }
      return flattenedFeatures
    },
    {}
  )

  const formattedGroupedFeatures =
    flattenedConfigFeatures &&
    Object.entries(
      groupBy(
        Object.entries(flattenedConfigFeatures)
          .filter(
            ([feature, featureExists]) =>
              !feature.includes('additional') && featureExists
          )
          .map(([featureItem, featureItemExists]) => {
            const locationFeature = featureMenu?.find((menuItem) => {
              return menuItem?.configKey === featureItem
            })

            const additionalInfo =
              flattenedConfigFeatures?.additionalInfo?.find(
                (additionalInfoItem) =>
                  additionalInfoItem?.configKey === featureItem
              )
            return {
              featureItem,
              featureItemExists,
              ...(locationFeature || {}),
              additional: additionalInfo,
            }
          }),
        'category'
      )
    )
      ?.map(([category, featureItems]) => {
        return {
          category: category !== 'null' && category,
          minimal: category !== 'amenities',
          featureItems,
        }
      })
      ?.filter((group) => {
        return group.category !== 'undefined'
      })

  useEffect(() => {
    if (weatherData) {
      // if (weatherData?.forecast) {
      //   const forecast = weatherData?.forecast
      //   setForecast(forecast)
      // }
      if (weatherData?.waveHeight) {
        const {
          airTemperature,
          waterTemperature,
          waveHeight,
          windDirection,
          windSpeed,
        } = weatherData
        setAirTemperature(airTemperature)
        setWaterTemperature(waterTemperature)
        setWaveHeight(waveHeight)
        setWindDirection(windDirection)
        setWindSpeed(windSpeed)
      }
      if (weatherData?.tideData) {
        const tideData = weatherData?.tideData
        let tideDataEnriched = tideData && [...tideData]
        if (tideData) {
          tideDataEnriched.indexOf((a) => a.x.includes('00:')) === -1
            ? (tideDataEnriched = [
                {
                  t: `${moment(new Date()).format('YYYY-MM-DD')} 00:01`,
                  v: '2',
                },
                ...tideDataEnriched,
              ])
            : console.log('This item already exists')

          tideDataEnriched.indexOf((a) => a.x.includes('23:')) === -1
            ? tideDataEnriched.push({
                t: `${moment(new Date()).format('YYYY-MM-DD')} 23:59`,
                v: '2',
                type: 'H',
              })
            : console.log('This item already exists')
        }

        setTideData(tideDataEnriched)
      }

      if (weatherData?.uvData) {
        const uvIndex = weatherData?.uvData

        setUvIndex(uvIndex)
      }
    }
  }, [weatherData, globalOverride])

  // const weatherIcon =
  //   forecast &&
  //   weatherIcons.contentfulWeatherIcons &&
  //   getForecastIcon(forecast, weatherIcons.contentfulWeatherIcons)

  const { riskColor } = useGenerateLocationRiskInformation({
    aquaticRiskLevels,
    weatherData,
  })

  return (
    <>
      <WidgetContainer>
        <Flex
          sx={{
            alignItems: 'center',
            textAlign: ['left'],
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Flex sx={{ gap: ['20px'], flexWrap: 'wrap' }}>
            <h1
              sx={{
                fontSize: ['32px', null, '36px'],
                my: '5px',
                flex: 1,
              }}
            >
              {beachName}
            </h1>

            <Flex
              sx={{
                alignItems: 'center',
                my: '5px',
              }}
            >
              <p
                sx={{
                  paddingLeft: ['7px', '10px'],
                  variant: 'styles.p',
                  fontWeight: 'bold',
                  margin: 0,
                }}
              >
                {airTemperature?.formatted || ''}
              </p>
              <Flex
                sx={{
                  paddingLeft: '15px',
                  variant: 'styles.p',
                  margin: 0,
                }}
              >
                {uvIndex?.formatted ? (
                  <Flex
                    sx={{
                      alignItems: 'center',
                      bg: getUVIndexColor(uvIndex?.uvIndex?.value),
                      p: '4px 6px',
                      borderRadius: '10px',
                      color: 'white',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      minWidth: '80px',
                    }}
                  >
                    {`${uvIndex?.formatted} - UV Index`}
                  </Flex>
                ) : (
                  ''
                )}
              </Flex>
            </Flex>
          </Flex>

          <SubscribeButton
            beachName={beachName}
            watchtowerAgencyId={watchtowerAgencyId}
            agencyLogo={agencyLogo}
            buttonStyles={{
              display: ['none', 'flex'],
            }}
            hideSubscribeButton={hideSubscribeButton}
          />
        </Flex>
        <Grid
          columns={[1, 2, 4]}
          gap={2}
          sx={{
            paddingBottom: ['10px', '30px'],
            paddingTop: ['15px', '30px'],
            borderBottom: device !== 'tv' && '1px solid',
            borderColor: 'iconCircle',
            mb: '20px',
          }}
        >
          <OverviewBullet
            title="Water Temperature"
            minHeight="160px"
            value={waterTemperature?.formatted}
            description={
              loading
                ? '...'
                : waterTemperature?.formatted
                ? waterTemperature.description
                : 'Condition unavailable'
            }
          />

          <OverviewBullet
            title="Wave Height"
            minHeight="160px"
            customIcon={<Surfer height={35} width={35} color={riskColor} />}
            value={waveHeight?.formatted}
            description={
              loading
                ? '...'
                : waveHeight?.formatted
                ? waveHeight.description
                : 'Condition unavailable'
            }
          />

          <OverviewBullet
            title="Wind"
            minHeight="160px"
            value={windSpeed?.formatted}
            description={
              loading
                ? '...'
                : windSpeed?.formatted
                ? windDirection.formatted
                : 'Condition unavailable'
            }
          />

          {tideData && tideData?.length && (
            <TideWidget
              tz={tz}
              data={tideData.map((t) => ({
                x: parseInt(moment(t.t).format('H')),
                y: t.v,
              }))}
            />
          )}
        </Grid>
        {data?.schedules?.length > 0 && (
          <Box sx={{ mb: '30px' }}>
            {data?.schedules?.map((schedule, index) => (
              <Flex
                sx={{ mb: '10px', gap: '5px', flexDirection: 'column' }}
                key={index}
              >
                <h6>{schedule.title}</h6>
                {schedule?.hours?.map((item, index) => (
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid',
                      borderColor: 'iconCircle',
                      pb: '5px',
                    }}
                    key={index}
                  >
                    <p
                      sx={{
                        variant: 'styles.p',
                        margin: 0,
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      sx={{
                        variant: 'styles.p',
                        margin: 0,
                      }}
                    >
                      {item?.closed ? (
                        'Closed'
                      ) : (
                        <>
                          {item.open} - {item.close}
                        </>
                      )}
                    </p>
                  </Flex>
                ))}
              </Flex>
            ))}
          </Box>
        )}
        {device !== 'tv' && (
          <CollapsibleContent
            markdown={true}
            content={data.description.childMarkdownRemark.html}
            linesToShow={4}
            textStyles={{ fontSize: [2, 3] }}
            buttonStyles={{
              fontSize: ['16px', '16px'],
            }}
          />
        )}
      </WidgetContainer>
      {formattedGroupedFeatures?.length > 0 && device !== 'tv' && (
        <WidgetContainer>
          {formattedGroupedFeatures?.map((group, index) => (
            <Box sx={{ mb: 4 }} key={index}>
              {group?.category && (
                <h2
                  sx={{ textTransform: 'capitalize', mt: 0, mb: [2, null, 3] }}
                >
                  {group.category}
                </h2>
              )}
              <CollapsibleContent
                buttonStyles={{
                  fontSize: [16, 16],
                }}
                height={'auto'}
                content={
                  <Grid gap={['.5rem', null, '1rem']} columns={[1, 2, null, 4]}>
                    {group.featureItems
                      .filter((item) => {
                        return group.minimal
                          ? item.featureItemExists === 'yes'
                          : true
                      })
                      .map((item, index) => (
                        <FeatureItem key={index} {...item} />
                      ))}
                  </Grid>
                }
              />
            </Box>
          ))}
        </WidgetContainer>
      )}
    </>
  )
}

const FeatureItem = ({ title, additional, icon, featureItemExists }) => {
  const feature = (
    <Flex
      sx={{
        gap: 0,
        alignItems: 'center',
        width: '100%',
        borderRadius: '0.2rem',
        padding: 1,
        '&:hover': {
          backgroundColor: additional ? 'gray200' : 'none',
          transition: 'background-color 0.2s',
        },
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          gap: ['8px', '10px'],
          // flex: '1 1 auto',
        }}
      >
        {icon?.file?.url && featureItemExists !== 'no' && (
          <IconCircle>
            <img
              src={icon?.file?.url}
              alt={title}
              sx={{
                height: ['24px', '30px'],
              }}
            />
          </IconCircle>
        )}
        {featureItemExists === 'no' && (
          <IconCircle>
            <CheckboxUnchecked
              color={theme.colors.gray600}
              title={`${title} unavailable`}
            />
          </IconCircle>
        )}
        <p
          sx={{
            fontWeight: 600,
            margin: 0,
            color: featureItemExists === 'no' ? 'gray600' : 'text',
          }}
        >
          {title}
        </p>
      </Flex>
      {additional && (
        <IconCircle>
          <InfoIcon
            color={theme.colors.text}
            height={20}
            width={20}
            title={`${title} additional information`}
          />
        </IconCircle>
      )}
    </Flex>
  )

  return additional ? (
    <ModalButton
      modalContentStyles={{
        px: '25px',
        maxHeight: '70vh',
      }}
      modalHeaderStyles={{
        fontSize: '36px',
        p: ['25px'],
      }}
      modalHeader={title}
      renderModalContent={() => (
        <div
          dangerouslySetInnerHTML={{
            __html: additional.info.childMarkdownRemark.html,
          }}
        />
      )}
      buttonStyles={{ justifyContent: 'flex-start', padding: '0px' }}
      buttonText={feature}
      buttonVariant="naked"
    />
  ) : (
    feature
  )
}

function getUVIndexColor(val) {
  if (val <= 2) return 'green'
  if (val <= 5 && val > 2) return 'yellow'
  if (val <= 7 && val > 5) return 'redOrange'
  return 'highRisk'
}

// const getForecastIcon = (forecast, weatherIcons) => {
//   let weatherIcon
//   switch (forecast.toLowerCase()) {
//     case 'rain':
//     case 'raining':
//     case 'rain showers':
//       weatherIcon = weatherIcons.raining.file.url
//       break
//     case 'cloudy':
//     case 'overcast':
//     case 'patchy fog':
//     case 'partly cloudy':
//     case 'mostly cloudy':
//     case 'mostly clear':
//     case 'mostly sunny':
//     case 'partly sunny':
//     case 'fog':
//     case 'fog/mist':
//     case 'chance rain showers':
//       weatherIcon = weatherIcons.partlyCloudy.file.url
//       break
//     case 'sunny':
//     case 'clear':
//       weatherIcon = weatherIcons.sunny.file.url
//       break
//     case 'thunder':
//     case 'thunderstorm':
//     case 'slight chance showers and thunderstorms':
//       weatherIcon = weatherIcons.thunderstorm.file.url
//       break
//     case 'windy':
//     case 'wind':
//     case 'partly cloudy and windy':
//     case 'overcast and windy':
//       weatherIcon = weatherIcons.windy.file.url
//       break
//     case 'snowy':
//     case 'snow':
//     case 'snowstorm':
//     case 'hail':
//       weatherIcon = weatherIcons.snowy.file.url
//       break
//     default:
//       weatherIcon = null
//   }
//   return weatherIcon
// }
