/** @jsx jsx */
import React from 'react'
import { Box, jsx } from 'theme-ui'
import Calendar from '../../icons/calendar.svg'
import { descriptionOptions, convertRichText } from '../../utils/rich-text'
import LocationPin from '../../icons/location-pin.svg'
import { AddToCalendarButtons } from './AddToCalendarButtons'

const EventModalContent = (props) => {
  const { overallTitle, dateInfo } = props
  const { eventTitle, eventStreetAddress, eventCity, eventDescription } =
    props.eventInfo

  let eventDescriptionConverted
  if (eventDescription) {
    eventDescriptionConverted = convertRichText(
      eventDescription,
      descriptionOptions
    )
  }

  return (
    <>
      <Box
        sx={{
          margin: 'auto',
          textAlign: 'center',
          borderBottom: ['0', '1px solid rgb(229, 229, 229)'],
          padding: ['0px', '0px 12.5% 10px'],
        }}
      >
        <h2
          sx={{
            margin: '0 0 10px 0',
            display: ['none', 'block'],
            fontSize: '24px',
          }}
        >
          {overallTitle}
        </h2>
      </Box>
      <div sx={{ width: ['96%', '100%'], margin: '0 auto' }}>
        <div sx={{ variant: 'cards.modalInfoCard' }}>
          <h2
            sx={{
              variant: 'styles.cardHeader',
              marginBottom: '30px',
            }}
          >
            {eventTitle}
          </h2>
          <div sx={{ marginTop: ['20px', 0] }}>
            <div
              sx={{
                display: 'flex',
                marginLeft: ['5px', 0],
                marginBottom: '20px',
                alignItems: 'center',
              }}
            >
              <img src={Calendar} sx={{ marginRight: '22px' }} />
              <p sx={{ fontSize: ['12px', '16px'], margin: '0' }}>{dateInfo}</p>
            </div>
            <div
              sx={{
                display: 'flex',
                marginLeft: ['5px', 0],
                marginBottom: [0, '20px'],
                alignItems: 'center',
              }}
            >
              <img src={LocationPin} sx={{ marginRight: '22px' }} />
              <p sx={{ fontSize: ['12px', '16px'], margin: '0' }}>
                {eventStreetAddress},{eventCity}
              </p>
            </div>
          </div>
        </div>
        <div sx={{ textAlign: 'center' }}>
          <AddToCalendarButtons {...props.eventInfo} />{' '}
        </div>
        <div sx={{ variant: 'cards.modalInfoCard' }}>
          <h2 sx={{ variant: 'styles.cardHeader' }}>About</h2>
          {eventDescriptionConverted}
        </div>
      </div>
    </>
  )
}

export default EventModalContent
