/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Fragment, useEffect, useState } from 'react'
import { jsx, Box } from 'theme-ui'

import { Line } from 'react-chartjs-2'

import styled from '@emotion/styled'
import theme from '../../../gatsby-plugin-theme-ui'

import moment from 'moment-timezone'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chartjs-adapter-moment'

const StyledSummaryCard = styled.div`
  position: relative;
  padding: 0;
  min-height: 100px;
`

const TideWidget = React.memo(({ data, tz, loading, size, color }) => {
  const [gradient, setGradient] = useState()
  const graphColor = theme.colors.disclaimer
  const darkPurple = theme.colors.purple

  useEffect(() => {
    const ctx = document.getElementById('canvas').getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, '#FFFFFF')
    gradient.addColorStop(1, '#CDD4FF')
    gradient.addColorStop(0.4, '#CDD4FF')
    setGradient(gradient)
  }, [])

  return (
    <StyledSummaryCard
      sx={{
        border: '1px solid #EAEDFF',
        borderRadius: ['10px', '8px'],
        minHeight: ['180px', null, '160px'],
      }}
      title="Tides"
      accentColor={color}
      size={size}
    >
      {loading ? (
        <div />
      ) : (
        <>
          {data ? (
            <>
              {data && data.length > 1 ? (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: -10,
                    left: -3,
                    top: 10,
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      borderLeft: [
                        '1px dashed #8F8CFF',
                        null,
                        '3px dashed #8F8CFF',
                      ],
                      width: '2px',
                      left: `${
                        (moment()
                          .tz(tz || 'America/Los_angeles')
                          .hour() /
                          24) *
                        100
                      }%`,
                      zIndex: 10,
                      top: -10,
                      right: 0,
                      bottom: 10,
                    }}
                  >
                    <Box
                      sx={{
                        mt: '3px',
                        pl: '5px',
                        fontWeight: 'bold',
                        ml:
                          moment()
                            .tz(tz || 'America/Los_angeles')
                            .hour() > 15 && '-95px',
                        fontSize: [0, 0, 0, 1],
                        width: '95px',
                      }}
                    >
                      Tides:{' '}
                      {moment()
                        .tz(tz || 'America/Los_angeles')
                        .format('hh A')}
                    </Box>
                  </Box>
                  <Line
                    id="canvas"
                    plugins={[ChartDataLabels]}
                    options={{
                      maintainAspectRatio: false,
                      layout: {
                        padding: {
                          left: 20,
                          right: 20,
                          top: 20,
                          bottom: 20,
                        },
                      },
                      scales: {
                        y: {
                          display: false,
                          type: 'linear',
                        },

                        x: {
                          display: false,
                          max: 24,
                          min: 0,
                          ticks: {
                            stepSize: 1,
                          },
                        },
                      },

                      plugins: {
                        // Change options for ALL labels of THIS CHART
                        datalabels: {
                          offset: 5,
                          align: 'start',
                          color: 'black',
                          labels: {
                            title: {
                              font: {
                                family: 'Quicksand',
                              },
                            },
                          },
                          formatter: (value) => {
                            if (value.x === 23 || value.x === 0) {
                              return ''
                            }
                            return `${
                              value.x < 3 ? '          ' : ''
                            }${parseFloat(value.y).toFixed(2)} ${
                              value.x > 18 ? '          ' : ''
                            }`
                          },
                        },

                        tooltip: {
                          backgroundColor: 'transparent',
                          bodyColor: 'black',
                          titleColor: 'black',

                          callbacks: {
                            label: () => '',
                            title: () => '',
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        16, 17, 18, 19, 20, 21, 22, 23, 24,
                      ],
                      datasets: [
                        {
                          borderColor: 'transparent',
                          fill: 'start',
                          lineTension: 0.4,
                          backgroundColor: gradient,
                          borderCapStyle: 'butt',
                          borderDash: [],
                          borderDashOffset: 0.0,
                          borderJoinStyle: 'miter',
                          pointBorderColor: darkPurple,
                          pointBackgroundColor: '#fff',
                          pointBorderWidth: 1,
                          pointHoverRadius: 9,
                          pointHoverBackgroundColor: theme.colors.disclaimer,
                          pointHoverBorderColor: graphColor,
                          pointHoverBorderWidth: 2,
                          pointRadius: 4,
                          pointHitRadius: 10,
                          data,
                        },
                      ],
                    }}
                  />
                </div>
              ) : (
                <Fragment />
              )}
            </>
          ) : (
            <>No data..</>
          )}
        </>
      )}
    </StyledSummaryCard>
  )
})

export default TideWidget
