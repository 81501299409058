/** @jsx jsx */
import React from 'react'
import { jsx, Flex } from 'theme-ui'
import WidgetContainer from '../../shared/WidgetContainer'

import MoreOrLessIcon from '../../../icons/more-or-less-icon.svg'
import { useSearchParams } from '../../../hooks/useSearchParams'
import AgencyPageLink from './AgencyPageLink'

export default function AgencyPageBlock({
  title,
  rules,
  qrCode,
  beachWebsiteLink,
}) {
  const { device } = useSearchParams()

  const rulesToShow = rules.slice(0, 4)

  const rulesWithShowMore = [
    ...rulesToShow,
    {
      id: 'showMore',
      title: 'Show all',
      icon: {
        file: {
          url: MoreOrLessIcon,
        },
      },
    },
  ]

  return (
    <>
      {device !== 'tv' && (
        <WidgetContainer
          styles={{
            padding: '40px 20px',
            position: 'relative',
          }}
        >
          <div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              textAlign: 'center',
              mb: '20px',
            }}
          >
            <div
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2
                sx={{
                  variant: 'styles.cardHeader',
                  marginLeft: '0',
                }}
              >
                {title}
              </h2>
            </div>
          </div>
          <Flex
            sx={{
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: '15px',
              justifyContent: 'flex-start',
            }}
          >
            {rulesWithShowMore.map((rule, k) => {
              return (
                <AgencyPageLink
                  title={title}
                  currentRule={rule}
                  key={k}
                  rules={rules}
                />
              )
            })}
          </Flex>
        </WidgetContainer>
      )}
      {device === 'tv' && (
        <WidgetContainer>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <div>
              <h3 sx={{ margin: 0, variant: 'cardHeader' }}>
                For More Information About Rules and Frequently Asked Questions
              </h3>
              <div>
                <p sx={{ variant: 'styles.p' }}>
                  Visit <a href={beachWebsiteLink}>{beachWebsiteLink}</a> or
                  scan QR code
                </p>
              </div>
            </div>
            <Flex sx={{ justifyContent: 'center' }}>
              <img
                src={qrCode?.file?.url}
                sx={{ width: '80px', height: '80px', objectFit: 'contain' }}
              />
            </Flex>
          </Flex>
        </WidgetContainer>
      )}
    </>
  )
}
